import banner from "../../assets/intro_yaks/Mask group.png";
import AppStore from "../../assets/intro_yaks/app_store.png";
import PlayStore from "../../assets/intro_yaks/google-play-badge 1.png";
import { useLocation } from "react-router-dom";

const IntroYaks = () => {
  const location = useLocation();
  const path = location.pathname;
  const isEN = path == "/en";
  return (
    <div className="w-full h-[400px] xl:h-[700px] 2xl:h-[700px] relative bg-gradient-to-b from-#FFDDDD via-#F2D6EF ">
      <div className="w-full absolute top-0 left-0 md:mt-16 lg:px-20 xl:px-32 mt-5">
        <div className=" items-center text-4xl font-bold text-main-text leading-[60px] tracking-wide ml-4 md:text-5xl md:w-[500px] md:leading-[80px] xl:ml-16">
          Travel to Earn <br />
          {isEN ? "Earn " : "Earn "}
          <span className="text-4xl text-transparent bg-clip-text bg-gradient-to-r from-red-gradient to-purple-gradient md:text-5xl">
            {isEN ? "Your Experiences" : "Your Experiences"}
          </span>
        </div>
        <div className="mx-4 mt-2 leading-8 text-main-text xl:w-1/2 xl:ml-16">
          <u>
            {isEN
              ? "Amazing Thailand NFTs Season 3"
              : "Amazing Thailand NFTs Season 3"}
          </u>{" "}
          {isEN
            ? "Amazing Thailand NFTs Season 3 is back again with a new dimension of real-world travel combined with blockchain technology, allowing you to collect special NFT art pieces and enjoy the benefits of traveling throughout Thailand through the YAKS app."
            : "กลับมาอีกครั้ง กับการเปิดมิติการท่องเที่ยวโลกจริงผสานเข้ากับเทคโนโลยีจากโลก Blockchain พร้อมให้สะสมชิ้นงานศิลปะ NFT คอลเลคชั่นพิเศษ เพื่อรับสิทธิประโยชน์ในการท่องเที่ยวทั่วไทย ผ่านแอปพลิเคชั่น YAKS"}
        </div>
        <div className="flex flex-row justify-center xl:justify-start md:mt-5 xl:ml-16">
          <div>
            <a
              href="https://apps.apple.com/app/yaks-x-amazing-thailand-nfts/id6443812299"
              target="_blank"
            >
              <img src={AppStore} />
            </a>
          </div>
          <div>
            <a
              href="https://storage.googleapis.com/yaks-app-image/app-release.apk"
              target="_blank"
            >
              <img src={PlayStore} />
            </a>
          </div>
        </div>
      </div>

      <img
        className="w-full h-full object-none object-center  xl:object-cover "
        src={banner}
      />
    </div>
  );
};

export default IntroYaks;
