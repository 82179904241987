const TermAndCondition = () => {
  return (
    <div className="mx-5 xl:mx-28 mb-28">
      <div className="text-lg font-bold">
        ข้อตกลงผู้ใช้บริการ (User Agreement) for YAKS x Amazing Thailand NFT
      </div>
      <p className="mt-5">
        ข้อตกลงฉบับนี้ทำขึ้นระหว่าง บริษัท ไดรฟ์ ดิจิทัล จำกัด
        (ซึ่งต่อไปนี้เรียกว่า “บริษัท”) ฝ่ายหนึ่ง กับ ลูกค้าซึ่งเป็นผู้ใช้บริการ
        (ซึ่งต่อไปนี้เรียกว่า “ลูกค้า”) อีกฝ่ายหนึ่ง
      </p>
      <p>
        โดยลูกค้ามีความประสงค์จะซื้อขายสินทรัพย์ดิจิทัลกับบริษัท
        โดยยินยอมที่จะปฏิบัติตามกฎระเบียบข้อบังคับเกี่ยวกับสินทรัพย์ดิจิทัล
        บริษัทและลูกค้าจึงได้ตกลงทำข้อตกลงกันโดยมีเงื่อนไขและรายละเอียดดังต่อไปนี้
      </p>
      <div className="mt-3 text-m font-bold">1. ในข้อตกลงนี้ คำว่า</div>
      <div className="mt-3">
        “สินทรัพย์ดิจิทัล” ให้หมายความรวมถึง คริปโทเคอร์เรนซี่และโทเคนดิจิทัล
        หรือสินทรัพย์ดิจิทัลอื่นใดที่สำนักงานคณะกรรมการกำกับหลักทรัพย์และตลาดหลักทรัพย์จะกำหนด
      </div>
      <div className="mt-3">
        “ศูนย์ซื้อขายสินทรัพย์ดิจิทัล” ให้หมายความรวมถึง
        ศูนย์กลางหรือเครือข่ายใด ๆ
        ที่จัดให้มีขึ้นเพื่อการซื้อขายหรือแลกเปลี่ยนสินทรัพย์ดิจิทัล
        โดยการจับคู่หรือหาคู่สัญญาให้
        หรือการจัดระบบหรืออำนวยความสะดวกให้ผู้ประสงค์จะซื้อขายหรือจะแลกเปลี่ยนสินทรัพย์ดิจิทัลสามารถทำความตกลงหรือจับคู่กันได้
        โดยกระทำเป็นทางค้าปกติ แต่ทั้งนี้
        ไม่รวมถึงศูนย์กลางหรือเครือข่ายในลักษณะที่คณะกรรมการ ก.ล.ต. ประกาศกำหนด
      </div>
      <div className="mt-3">
        “ซื้อ” ให้หมายความรวมถึง การจองซื้อสินทรัพย์ดิจิทัลด้วย
      </div>
      <div className="mt-3">
        “ลูกค้า” ให้หมายความรวมถึง
        ลูกค้าที่ได้ลงทะเบียนสร้างบัญชีผ่านทางเว็บไซต์และแอปพลิเคชันบริษัท
        โดยตกลงและยอมรับเงื่อนไขและข้อกำหนดที่ระบุไว้ภายใต้ข้อตกลงฉบับนี้
        และยอมรับว่าลูกค้าจะรับความเสี่ยงเรื่องผลตอบแทนในการซื้อขาย แลกเปลี่ยน
        หรือถือครองสินทรัพย์ดิจิทัลในเว็บไซต์และแอปพลิเคชันของบริษัทด้วยตนเองฝ่ายเดียว
      </div>
      <div className="mt-3">
        “เว็บไซต์” (Website) หมายถึง https://yaks.club/ หรือเว็บไซต์อื่น ๆ
        ที่บริษัทจะแจ้งให้ลูกค้าทราบ
      </div>
      <div className="mt-3">
        “แอปพลิเคชัน” (Application) หมายถึง โปรแกรม หรือ กลุ่มของโปรแกรม
        ยักษ์แอป (YAKS App)
      </div>
      <div className="mt-3">
        “สำนักงาน ก.ล.ต.” หมายถึง
        สำนักงานคณะกรรมการกำกับหลักทรัพย์และตลาดหลักทรัพย์
      </div>
      <div className="mt-3 text-m font-bold">1. การสร้างบัญชีผู้ใช้งาน</div>
      <div className="mt-3">
        2.1 คุณสมบัติลูกค้า
        <div className="mt-3">(1) มีอายุตั้งแต่ 18 (สิบแปด) ปีบริบูรณ์</div>
      </div>

      <div className="mt-3">
        2.2 การลงทะเบียนบัญชีผู้ใช้
        <div className="mt-3">
          (1) เพื่อใช้บริการของบริษัท
          ลูกค้าต้องลงทะเบียนในเว็บไซต์และแอปพลิเคชันของบริษัทด้วย
          การสร้างบัญชีผู้ใช้ โดยกรอกข้อมูล ส่วนบุคคล (ข้อมูลการแสดงตน)
          ตามนโยบายการรับลูกค้าของบริษัทและการปฏิบัติตามกฎหมายฟอกเงินและกฎหมายป้องกันและปราบปรามการสนับสนุนทางการเงินแก่การก่อการร้าย
          และการแพร่ขยายอาวุธที่มีอานุภาพทำลายล้างสูง เช่น ชื่อ นามสกุล สัญชาติ
          เลขประจำตัวประชาชน เลขที่หนังสือเดินทาง หมายเลขโทรศัพท์มือถือ ที่อยู่
          วันเกิด อาชีพ ที่มา/ระดับรายได้ อีเมล และข้อมูลอื่น ๆ
          ที่เกี่ยวข้องให้ถูกต้อง
        </div>
        <div className="mt-3">
          (2)
          ลูกค้าตกลงและยอมรับว่าเป็นดุลยพินิจฝ่ายเดียวของบริษัทในการอนุมัติบัญชีผู้ใช้งาน
          และบริษัทอาจปฏิเสธการสร้างบัญชีผู้ใช้ของลูกค้า
          หรือจำกัดการแลกเปลี่ยนที่ลูกค้ามีสิทธิและรักษาไว้ตลอดการถือบัญชี
        </div>
        <div className="mt-3">
          (3) ลูกค้าต้องให้ข้อมูลส่วนบุคคลของลูกค้า
          และในการส่งข้อมูลส่วนบุคคลดังกล่าวให้บริษัทนั้น ลูกค้ารับรองว่า
          ข้อมูลทั้งหมดนั้นถูกต้องเป็นจริง
          และลูกค้าตกลงที่จะแจ้งปรับปรุงข้อมูลดังกล่าวให้เป็นปัจจุบัน
          กรณีมีการเปลี่ยนแปลงข้อมูล
        </div>
        <div className="mt-3">
          (4) ลูกค้ายินยอมที่จะแจ้งและจัดหาข้อมูลดังนี้
          พร้อมทั้งจัดทำคำชี้แจงเพื่ออธิบายรายละเอียดเกี่ยวกับข้อมูลดังกล่าวให้แก่บริษัท
          สำนักงาน ก.ล.ต. หรือหน่วยของรัฐอื่นใดที่มีอำนาจตามกฎหมาย เมื่อบริษัท
          และ/หรือ หน่วยงานต่าง ๆ ดังกล่าวร้องขอ
          หรือเพื่อให้เป็นไปตามหลักเกณฑ์ที่หน่วยงานดังกล่าวกำหนด
          <div className="mt-3">
            (4.1) ข้อมูลที่สามารถระบุความมีตัวตนที่แท้จริงของลูกค้า
            และ/หรือผู้รับประโยชน์จากการซื้อขายสินทรัพย์ดิจิทัลของลูกค้าทุกรายทุกทอด
          </div>
          <div className="mt-3">(4.2) วัตถุประสงค์ในการลงทุนของลูกค้า</div>
          <div className="mt-3">
            (4.3)
            ข้อมูลเกี่ยวกับการซื้อขายของลูกค้าไม่ว่าการซื้อขายดังกล่าวจะกระทำผ่านบริษัทหรือไม่ก็ตาม
          </div>
        </div>
      </div>
      <div className="mt-3">
        2.3 การใช้งานบัญชี
        <div className="mt-3">
          (1)
          ลูกค้าตกลงที่จะผูกพันตามเงื่อนไขและข้อตกลงของเว็บไซต์และแอปพลิเคชันทุกประการ
        </div>
        <div className="mt-3">
          (2) ลูกค้าตกลงที่จะรับผิดชอบในการเก็บรักษารหัสประจำตัว(Pins)
          รหัสประจำตัวผู้ใช้ (“User ID”) และรหัสผ่าน (“Password”)
          โปรแกรมเชื่อมต่อระหว่างเครื่องคอมพิวเตอร์ (API) ของตนไว้เป็นความลับ
          และรวมตลอดถึงการดำเนินธุรกรรมใด ๆ
          ที่กระทำผ่านทางระบบการซื้อขายสินทรัพย์ดิจิทัลของบริษัท
          หากมีผู้ใดนำรหัสประจำตัว รหัสประจำตัวผู้ใช้
          และรหัสผ่านของลูกค้าไปใช้ในการดูข้อมูล
          และ/หรือส่งคำสั่งซื้อหรือขายสินทรัพย์ดิจิทัลไม่ว่าในกรณีใด ๆ
          ลูกค้าตกลงที่จะรับผิดชอบสำหรับการกระทำดังกล่าวเองทั้งสิ้น
        </div>
        <div className="mt-3">
          ลูกค้าตกลงและยอมรับว่าลูกค้ามีหน้าที่เก็บรักษารหัสประจำตัว
          รหัสประจำตัวผู้ใช้และรหัสผ่านของตนไว้เป็นความลับ
          และป้องกันไม่ให้บุคคลอื่นนำรหัสประจำตัว รหัสประจำตัวผู้ใช้
          และรหัสผ่านไปใช้
        </div>
        <div className="mt-3">
          (3) ลูกค้าต้องรับผิดชอบเพียงฝ่ายเดียวต่อการสูญหาย
          หรือการถูกล่วงละเมิดในข้อมูล
          และ/หรือข้อมูลส่วนตัวที่อาจส่งผลให้เกิดการเข้าถึงบัญชีผู้ใช้ของลูกค้าโดยไม่ได้รับอนุญาตจากบุคคลภายนอก
          รวมถึงความเสี่ยงจากการสูญเสีย หรือถูกขโมยเงินสกุลดิจิทัล
          และ/หรือเงินที่มีอยู่ในบัญชีของลูกค้าและบัญชีธนาคารใด ๆ
          และบัตรเครดิตที่เชื่อมกับบัญชีผู้ใช้ของลูกค้า แต่ทั้งนี้
          บริษัทจะรับผิดชอบกรณีการสูญหาย หรือการถูกล่วงละเมิดในข้อมูล
          ดังกล่าวเกิดจากความผิดพลาดของบริษัท
        </div>
        <div className="mt-3">
          (4) ลูกค้าต้องรับผิดชอบเพียงฝ่ายเดียวในการปรับปรุงที่อยู่อีเมล
          และข้อมูลการติดต่อให้เป็นปัจจุบัน ในหน้าบัญชีผู้ใช้ของลูกค้า
        </div>
        <div className="mt-3">
          (5) บริษัทจะไม่รับผิดชอบไม่ว่ากรณีใดต่อการสูญหายใด ๆ
          ที่ลูกค้าได้รับจากการถูกล่วงละเมิดบัญชีผู้ใช้ การเข้าสู่ระบบ
          หรือข้อมูลส่วนตัว ยกเว้นเป็นกรณีที่เกิดจากความผิดอย่างร้ายแรง
          หรือการจงใจละเลยการปฏิบัติหน้าที่ของบริษัท
        </div>
        <div className="mt-3">
          (6) การให้สิทธิแบบจำกัด
          <div className="mt-3">
            (6.1) บริษัทอนุญาตให้สิทธิแก่ลูกค้าอย่างจำกัด
            ไม่ใช่สิทธิแต่เพียงผู้เดียวและไม่สามารถโอนเปลี่ยนมือได้
            ในการเข้าถึงและใช้เนื้อหา
            รวมถึงข้อมูลที่แสดงไว้บนเว็บไซต์และแอปพลิเคชัน
            (ซึ่งต่อไปนี้รวมเรียกว่า “เนื้อหา”) เฉพาะเพื่อจุดประสงค์ที่อนุญาต
            และสำหรับการใช้บริการของบริษัท
            ภายใต้เงื่อนไขและข้อกำหนดที่บริษัทระบุไว้เป็นการเฉพาะ ทั้งนี้
            ห้ามลูกค้าใช้เนื้อหาดังกล่าว
            เพื่อจุดประสงค์อื่นนอกเหนือจากที่ระบุไว้
          </div>
          <div className="mt-3">
            (6.2) ลูกค้าตกลงและยินยอมว่า สิทธิและสิทธิประโยชน์ในเนื้อหา โลโก้
            เกี่ยวกับบริการของบริษัท
            หรือทุกข้อมูลที่นำเสนอไว้บนเว็บไซต์และแอปพลิเคชัน
            รวมถึงทรัพย์สินทางปัญญาอื่น เช่น ลิขสิทธิ์ เครื่องหมายการค้า
            เครื่องหมายจดทะเบียน หรือไม่จดทะเบียนอื่น ๆ (เรียกรวมกันว่า “IPRs”)
            ยังคงเป็นทรัพย์สินของบริษัทและผู้ให้ใช้สิทธิอื่น
          </div>
          <div className="mt-3">
            (6.3) ลูกค้าตกลงและยอมรับว่าจะไม่คัดลอก โอน จำหน่าย ขาย อนุญาต
            วิศวกรรมย้อนกลับ ดัดแปลง เผยแพร่ หรือมีส่วนในการโอนหรือการขาย
            หรือสร้างงานต่อเนื่อง หรือใช้ประโยชน์จาก IPRs
            ไม่ว่าทั้งหมดหรือบางส่วน
            โดยไม่ได้รับความยินยอมเป็นลายลักษณ์อักษรจากบริษัท
            และ/หรือผู้ให้ใช้สิทธิ
          </div>
        </div>
      </div>
      <div className="mt-3">
        (7) ความถูกต้องของเว็บไซต์และแอปพลิเคชัน
        <div className="mt-3">
          (7.1)
          บริษัทจะรับผิดชอบต่อข้อมูลที่เปิดเผยทั้งหมดบนเว็บไซต์และแอปพลิเคชัน
          ซึ่งลูกค้าอาจใช้ข้อมูลในการใช้บริการ กรณีเกิดข้อผิดพลาดทางด้านเทคนิค
          หรือความผิดพลาดจากการพิมพ์ที่เกิดจากบริษัท แต่ทั้งนี้
          ทางบริษัทไม่ได้ให้คำรับรอง
          หรือคำยืนยันเพื่อจูงใจให้ลูกค้าตัดสินใจดำเนินธุรกรรมใด ๆ
          ซึ่งเป็นการตัดสินใจโดยใช้ดุลยพินิจของลูกค้าแต่ฝ่ายเดียว
        </div>
        <div className="mt-3">
          (7.2)
          กรณีบริษัทเปลี่ยนแปลงข้อมูลเกี่ยวกับนโยบายผลิตภัณฑ์และการให้บริการ
          หรือข้อมูลสำคัญที่เกี่ยวข้องกับการใช้บริการของบริษัท เช่น ค่าธรรมเนียม
          หลักการคัดเลือกสินทรัพย์ หรือข้อมูลสำคัญเกี่ยวกับบริษัท
          บริษัทจะแจ้งให้ลูกค้าทราบล่วงหน้า
        </div>
        <div className="mt-3">
          (7.3) กรณีมีการเชื่อมโยงข้อมูลไปยังเนื้อหาของบุคคลภายนอก (เช่น
          เว็บไซต์และแอปพลิเคชันของบุคคลภายนอก)
          เพื่อความสะดวกของเว็บไซต์และแอปพลิเคชันของบริษัท
          แต่เนื้อหาข้อมูลดังกล่าวไม่ได้ถูกควบคุมโดยบริษัท กรณีดังกล่าว
          ลูกค้ารับทราบและยินยอมว่า บริษัทจะไม่รับผิดชอบหรือรับผิดในลักษณะใด ๆ
          ต่อข้อมูล เนื้อหา
          หรือการบริการที่มีอยู่ในเนื้อหาหรือเว็บไซต์และแอปพลิเคชันของบุคคลภายนอกที่เข้าถึงหรือเชื่อมต่อกับเว็บไซต์และแอปพลิเคชันของบริษัท
        </div>
      </div>
      <div className="mt-3">
        (8) การใช้งานต้องห้าม ธุรกิจต้องห้าม หรือการใช้งานที่มีเงื่อนไข
        <div className="mt-3">
          (8.1) การใช้บริการของบริษัท และปฏิสัมพันธ์ระหว่างลูกค้า ผู้ใช้รายอื่น
          และบุคคลภายนอก ลูกค้ารับประกันว่า ลูกค้าจะไม่ดำเนินการใด ๆ
          ที่เกี่ยวข้องกับธุรกิจที่ต้องห้าม การใช้งานต้องห้าม
          หรือการใช้งานที่มีเงื่อนไขที่กำหนดไว้ในภาคผนวก 1
        </div>
        <div className="mt-3">
          (8.2) บริษัทสงวนสิทธิ์ในการติดตาม ทบทวน คืน ยับยั้ง และ/หรือ
          เปิดเผยข้อมูลใด ๆ ที่จำเป็นได้ตลอดเวลาตามกฎหมาย ข้อบังคับ มาตรการลงโทษ
          กระบวนการดำเนินการตามกฎหมาย หรือคำสั่งของรัฐที่เหมาะสม
          ในส่วนที่เกี่ยวข้องกับธุรกิจที่ต้องห้าม การใช้งานต้องห้าม
          หรือการใช้งานที่มีเงื่อนไข
        </div>
        <div className="mt-3">
          (8.3) บริษัทขอสงวนสิทธิและเป็นดุลยพินิจฝ่ายเดียวในการยกเลิก และ/หรือ
          ยับยั้งบัญชีผู้ใช้ และ/หรือ ปิดกั้นธุรกรรมใด ๆ (เช่น ธุรกรรมการโอน
          ธุรกรรมจับคู่แลกเปลี่ยน หรือ การฝาก-ถอนกระเป๋าเงินบาท)
          หรือระงับและรักษาเงินทันที
          กรณีบัญชีผู้ใช้งานของลูกค้าถูกใช้เกี่ยวข้องกับธุรกิจที่ต้องห้าม
          การใช้งานต้องห้าม หรือ
          การใช้งานที่มีเงื่อนไขโดยไม่ต้องบอกกล่าวล่วงหน้า
        </div>
      </div>
      <div className="mt-3">
        (9) การระงับชั่วคราว การสิ้นสุด และการยกเลิกการเข้าใช้บริการ
        <div className="mt-3">
          (9.1) เป็นดุลยพินิจฝ่ายเดียวของบริษัท ซึ่งบริษัทอาจ (ก) ระงับชั่วคราว
          จำกัด หรือยุติการเข้าถึงการบริการทั้งหมดหรือบางส่วน และ/หรือ (ข) ปิด
          หรือ ยกเลิกบัญชีผู้ใช้ของลูกค้า ในกรณีต่าง ๆ เช่น
          <div className="mt-3">
            (1) บริษัทถูกกำหนดให้ดำเนินการดังกล่าว ตามหมายศาล คำสั่งศาล
            หรือคำสั่งที่ออกโดยรัฐ หรือ
          </div>
          <div className="mt-3">
            (2) บริษัทสงสัยว่า ลูกค้าใช้บัญชีผู้ใช้ของลูกค้า
            ในการดำเนินธุรกิจที่ต้องห้าม การใช้งานต้องห้าม
            หรือการใช้งานที่มีเงื่อนไข หรือ
          </div>
          <div className="mt-3">
            (3) การใช้บัญชีผู้ใช้ของลูกค้าอยู่ระหว่างการดำเนินคดี การสอบสวน
            หรือการดำเนินการโดยรัฐ หรือ
          </div>
          <div className="mt-3">
            (4)
            บริษัทได้ประเมินความเสี่ยงของการไม่ปฏิบัติตามระเบียบกฎหมายที่เกิดจากกิจกรรมที่ลูกค้าดำเนินการผ่านบัญชี
            ผู้ใช้ของลูกค้าที่สูงขึ้น หรือ
          </div>
          <div className="mt-3">
            (5) ผู้ให้บริการแก่บริษัทไม่สามารถรองรับการใช้งานของลูกค้า หรือ
          </div>
          <div className="mt-3">
            (6)
            ลูกค้ากระทำการที่บริษัทเห็นว่าขัดขวางการควบคุมของบริษัทไม่ว่ากรณีใด
            เช่น การเปิดบัญชีผู้ใช้หลายบัญชี
            หรือใช้โปรโมชันที่บริษัทเสนอในทางที่ผิด เป็นต้น
          </div>
          <div className="mt-3">
            (7) ลูกค้าฝ่าฝืนนโยบายหรือคำสั่งใด ๆ
            ที่บริษัทแจ้งให้ลูกค้าทราบตามแต่ระยะเวลา
          </div>
        </div>
        <div className="mt-3">
          (9.2) หากบริษัทระงับการใช้ชั่วคราว หรือปิดบัญชีผู้ใช้ของลูกค้า
          หรือยุติการใช้บริการของบริษัท ไม่ว่าด้วยเหตุผลใด
          บริษัทจะแจ้งการดำเนินการดังกล่าวไปยังลูกค้า
          ยกเว้นเป็นกรณีที่มีคำสั่งศาล หรือกระบวนการทางกฎหมายกำหนด
          ห้ามไม่ให้บริษัทแจ้งเตือนลูกค้า
          ลูกค้ารับทราบว่าการตัดสินใจของบริษัทในการดำเนินการใด ๆ
          ตามที่ระบุไว้ในข้อ 10 อาจดำเนินการภายใต้กฎเกณฑ์ที่เป็นความลับ
          ซึ่งจำเป็นต่อการจัดการความเสี่ยง และนโยบายความปลอดภัยอื่นของบริษัท
          ลูกค้าตกลงว่า
          บริษัทไม่มีหน้าที่เปิดเผยรายละเอียดของการจัดการความเสี่ยง
          และกระบวนการรักษาความปลอดภัยต่อลูกค้า
        </div>
        <div className="mt-3">
          (9.3) หากบริษัทระงับการใช้งานชั่วคราว หรือปิดบัญชีผู้ใช้ของลูกค้า
          หรือยุติการใช้บริการของบริษัท ไม่ว่าด้วยเหตุผลใด
          บริษัทสงวนสิทธิ์ให้ลูกค้าดำเนินการผ่านกระบวนการยืนยันตนก่อนที่ลูกค้าจะสามารถโอน
          หรือถอนสินทรัพย์ดิจิทัล
          หรือเงินตราตามกฎหมายออกจากกระเป๋าเงินสินทรัพย์ดิจิทัลหรือกระเป๋าเงินบาทได้
        </div>
        <div className="mt-3">
          (9.4) ลูกค้าอาจยกเลิกบัญชีผู้ใช้ของลูกค้าในเวลาใดก็ได้
          โดยติดต่อบริษัทด้วยตนเอง แจ้งความประสงค์จะขอปิดบัญชี
          และดำเนินการการถอนเงินทและสินทรัพย์ดิจิทัลออกจากบัญชีให้หมด
          โดยลูกค้าจะไม่เสียค่าใช้จ่ายสำหรับการยกเลิกบัญชีผู้ใช้นั้น
          แต่การยกเลิกบัญชีผู้ใช้นี้ลูกค้าจะไม่หลุดพ้นจากความรับผิด
          หรือความรับผิดชอบในการชำระเงินที่ยังค้างชำระกับบริษัท
        </div>
        <div className="mt-3">
          (9.5)
          ลูกค้าอนุญาตให้บริษัทยกเลิกและระงับธุรกรรมที่กำลังดำเนินการในขณะที่แจ้งยกเลิกบัญชีผู้ใช้ของลูกค้า
        </div>
      </div>
      <div className="mt-3">
        (10) ความเป็นส่วนตัวของผู้ใช้รายอื่น
        <div className="mt-3">
          (10.1)
          หากลูกค้าได้รับข้อมูลเกี่ยวกับผู้ใช้งานรายอื่นผ่านการใช้บริการของบริษัท
          ลูกค้าจะต้องเก็บข้อมูลดังกล่าวเป็นความลับ
          และใช้ข้อมูลนั้นเฉพาะเกี่ยวข้องกับการบริการของบริษัท
        </div>
        <div className="mt-3">
          (10.2) ลูกค้าจะต้องไม่เปิดเผย
          หรือเผยแพร่ข้อมูลของผู้ใช้อื่นแก่บุคคลภายนอก หรือใช้ข้อมูลนั้น
          เว้นแต่มีความจำเป็นเพื่อดำเนินธุรกรรมการโอน
          หรือธุรกรรมจับคู่แลกเปลี่ยนให้สำเร็จ หรือการดำเนินการอื่นที่ใกล้เคียง
          เพื่อการสนับสนุนการสรุปยอดบัญชีและการทำบัญชี
          ยกเว้นลูกค้าจะได้รับการแจ้งความยินยอมเฉพาะล่วงหน้าจากผู้ใช้ก่อน
          ลูกค้ายินยอมที่จะไม่ส่งอีเมลอันไม่พึงประสงค์
          ให้กับผู้ใช้อื่นผ่านบริการของบริษัท
        </div>
        <div className="mt-3">
          (10.3) ลูกค้าต้องรับประกันและปกป้องบริษัทจากการฟ้องร้อง
          การร้องเรียนจากผู้ใช้อื่น สำหรับความเสียหาย
          และค่าชดเชยที่เกิดจากการใช้ข้อมูลที่ได้รับของลูกค้า
        </div>
      </div>
      <div className="text-lg font-bold">1. การใช้ทั่วไป ข้อห้ามการใช้งาน</div>
      <div className="text-lg font-bold">3.1 ความสัมพันธ์ระหว่างคู่สัญญา</div>
      <div className="mt-3">
        ในการพิจารณาในทุกจุดประสงค์และทุกกรณี บริษัทคือผู้ให้บริการอิสระ
        และบทบัญญัติทั้งหมดของข้อตกลงฉบับนี้ จะไม่มีส่วนใดที่ถือว่า
        ก่อให้เกิดหรือจะตีความได้ว่า ลูกค้าและบริษัทเป็นหุ้นส่วนทางธุรกิจ
        บริษัทร่วมทุน หรือการร่วมมือกันเพื่อแสวงหากำไรในลักษณะใดลักษณะหนึ่ง
        หรือจะรับผิดร่วมกัน และไม่มีกรณีที่ลูกค้า
        หรือบริษัทจะมีฐานะเป็นตัวแทนของกันและกัน
      </div>
      <div className="text-lg font-bold">3.2 การยกเลิกสินทรัพย์ดิจิทัล</div>
      <div className="mt-3">
        (1) ด้วยการใช้ดุลยพินิจของบริษัทแต่เพียงผู้เดียวอันเป็นที่สุด
        บริษัทอาจยกเลิก (ก) สินทรัพย์ดิจิทัลที่รองรับ และ (ข)
        บริการกระเป๋าเงินดิจิทัลสกุลใดได้
      </div>
      <div className="mt-3">
        (2)
        บริษัทจะแจ้งให้ลูกค้าทราบเกี่ยวกับระยะเวลาที่ลูกค้าต้องดำเนินการถอนสินทรัพย์ดิจิทัลที่บริษัทจะยกเลิกออกจากบัญชีของลูกค้าที่มีอยู่ในระบบของบริษัท
        ภายในระยะเวลาที่กำหนด ทั้งนี้
        ลูกค้าต้องรับผิดชอบในการทำคำสั่งในการถอนด้วยตนเองผ่านระบบบนเว็บไซต์และแอปพลิเคชัน
      </div>
      <div className="mt-3">
        (3)
        เมื่อครบกำหนดระยะเวลาที่บริษัทกำหนดให้ลูกค้าดำเนินการถอนสินทรัพย์ดิจิทัลดังกล่าวออกจากระบบแล้ว
        ทางบริษัทจะดำเนินการปิดระบบการถอนสินทรัพย์ดิจิทัลที่จะถูกเพิกถอนดังกล่าวออกจากระบบในทันที
        โดยทางบริษัทจะถือสินทรัพย์ดิจิทัลดังกล่าวไว้แทนและเพื่อประโยชน์ของลูกค้า
        ทั้งนี้
        บริษัทอาจกำหนดเก็บค่าธรรมเนียมในการเก็บรักษาสินทรัพย์ดิจิทัลแทนลูกค้า
      </div>
      <div className="mt-3">
        (4)
        กรณีหากลูกค้าที่ยังไม่ดำเนินการถอนต้องการถอนสินทรัพย์ดิจิทัลที่ถูกปิดระบบการถอนแล้ว
        ลูกค้าจะสามารถถอนได้โดยดำเนินการติดต่อฝ่ายลูกค้าสัมพันธ์
      </div>
      <div className="text-lg font-bold">4. ข้อกำหนดทั่วไป</div>
      <div className="text-lg font-bold">4.1 ข้อตกลงการเปิดเผยข้อมูล</div>
      <div className="mt-3">
        ในกรณีที่บริษัทต้องปฏิบัติตามกฎหมาย คำสั่ง คำพิพากษา พันธะสัญญา
        หรือข้อตกลงใด ๆ หรือในกรณีมีเหตุอันควร ไม่ว่าจะเกิดขึ้นแล้วในขณะนี้
        หรือจะเกิดขึ้นในอนาคต ลูกค้าตกลงยินยอมให้บริษัทสามารถเปิดเผยข้อมูล
        เกี่ยวกับ
        <div className="mt-3">
          (1) ความมีตัวตนที่แท้จริงของลูกค้า
          และ/หรือผู้รับผลประโยชน์ที่แท้จริงจากการใช้บริการหรือทำธุรกรรมกับบริษัท
        </div>
        <div className="mt-3">
          (2) การใช้บริการหรือทำธุรกรรมกับบริษัทตามคำขอฉบับนี้
        </div>
        <div className="mt-3">
          (3) วัตถุประสงค์ในการทำธุรกรรมตามคำขอฉบับนี้ และ
        </div>
        <div className="mt-3">
          (4) ข้อมูลหรือเอกสารใด ๆ ที่เกี่ยวข้องกับการทำธุรกรรมตามคำขอฉบับนี้
          ให้แก่
          <div className="mt-3">
            (ก) รัฐหรือหน่วยงานของรัฐ ทั้งในและต่างประเทศ
          </div>
          <div className="mt-3">(ข) หน่วยงานกำกับดูแล</div>
          <div className="mt-3">(ค) คู่สัญญาของบริษัท</div>
          <div className="mt-3">
            (ง) บุคคลหรือบริษัทที่เกี่ยวข้อง (Affiliates)
          </div>
          <div className="mt-3">
            (จ) พนักงาน ลูกจ้าง หรือผู้รับจ้างของบริษัทและบริษัทที่เกี่ยวข้องได้
            รวมถึงยินยอมให้บริษัทตรวจสอบข้อมูลของลูกค้า หรือดำเนินการใด ๆ
            เท่าที่จำเป็นสำหรับการปฏิบัติตามกฎหมาย คำสั่ง คำพิพากษา พันธะสัญญา
            หรือข้อตกลงใด ๆ ดังกล่าว
          </div>
        </div>
      </div>
      <div className="text-lg font-bold">4.2 การบอกกล่าว</div>
      <div className="mt-3">
        บรรดาหนังสือติดต่อ คำบอกกล่าว ทวงถาม
        เอกสารหรือหนังสืออื่นใดที่จะส่งให้แก่ลูกค้า
        ไม่ว่าจะส่งทางไปรษณีย์ลงทะเบียนหรือไม่ลงทะเบียน
        หรือให้คนของบริษัทนำไปส่งเองก็ดี
        ถ้าหากได้นำส่งไปยังที่อยู่ซึ่งลูกค้าได้ให้ไว้เป็นลายลักษณ์อักษรแก่บริษัทหรือที่อยู่ตามทะเบียนราษฎร์
        หรือสำนักทำการงานของลูกค้า หรือได้ส่งทางโทรสาร
        หรือโดยทางไปรษณีย์อิเล็กทรอนิกส์ (email)
        ไปยังหมายเลขโทรสารหรือที่อยู่ไปรษณีย์อิเล็กทรอนิกส์ (email address)
        ที่ลูกค้าได้ให้ไว้แล้ว ให้ถือว่าได้ส่งให้ลูกค้าแล้วโดยชอบแล้ว
        ทั้งนี้โดยไม่ต้องคำนึงว่าจะมีผู้รับไว้หรือไม่
        และถึงแม้ส่งให้แก่ลูกค้าไม่ได้
        เพราะที่อยู่ของลูกค้านั้นเปลี่ยนแปลงหรือรื้อถอนไปโดยลูกค้าไม่ได้แจ้งการเปลี่ยนแปลงหรือรื้อถอนนั้นให้บริษัททราบเป็นลายลักษณ์อักษร
        หรือส่งให้ไม่ได้เพราะหาที่อยู่ไม่พบก็ดี
        ให้ถือว่าลูกกค้าได้รับและทราบหนังสือติดต่อ คำบอกกล่าว ทวงถาม เอกสาร
        หรือหนังสืออื่นใดของบริษัทโดยชอบแล้วในวันที่พนักงานไปรษณีย์
        หรือคนของบริษัทได้ไปส่ง ณ ภูมิลำเนานั้น หรือในวันที่ส่งโทรสาร
        หรือส่งไปรษณีย์อิเล็กทรอนิกส์ได้ อนึ่งการแจ้งหรือบอกกล่าวใด ๆ
        ซึ่งตามข้อตกลงนี้ มิได้บังคับให้ทำเป็นลายลักษณ์อักษร
        หากบริษัทได้ดำเนินการแจ้งหรือบอกกล่าวแก่ลูกค้าด้วยวาจา
        หรือทางโทรศัพท์แล้ว
        ให้ถือเป็นการแจ้งหรือบอกกล่าวโดยชอบและลูกค้าได้รับทราบโดยชอบแล้ว
      </div>
      <div className="mt-3">
        ในกรณีที่บริษัทได้จัดส่งหนังสือ
        และ/หรือเอกสารที่เกี่ยวกับการซื้อขายสินทรัพย์ดิจิทัลให้แก่ลูกค้า
        เพื่อให้ลูกค้าลงนามรับรองและลูกค้าไม่ดำเนินการใด ๆ
        ภายในระยะเวลาที่บริษัทกำหนด
        ลูกค้าตกลงยินยอมให้บริษัทมีสิทธิที่จะระงับการซื้อขายสินทรัพย์ดิจิทัลของลูกค้า
        โดยลูกค้าตกลงไม่เรียกร้องค่าเสียหายใด ๆ จากบริษัท
      </div>
      <div className="text-lg font-bold">
        4.3 ไวรัสคอมพิวเตอร์ และความขัดข้องอื่น
      </div>
      <div className="mt-3">
        บริษัทจะไม่รับผิดไม่ว่าลักษณะใดจากความเสียหาย หรือความขัดข้องใด ๆ
        อันเกิดจากไวรัสคอมพิวเตอร์ สปายแวร์ สแกร์แวร์ โทรจัน เวิร์ม และ
        โปรแกรมมัลแวร์ต่าง ๆ ที่อาจส่งผลกระทบต่อ คอมพิวเตอร์ หรืออุปกรณ์
        หรือการล้วงข้อมูลด้วยวิธีฟิชชิ่ง การปลอมแปลงหน้าเว็บไซต์และแอปพลิเคชัน
        และการโจมตีต่าง ๆ บริษัทแนะนำให้ลูกค้าใช้โปรแกรมป้องกันไวรัส
        และซอฟต์แวร์ป้องกันที่มีมาตรฐาน และสามารถเชื่อถือได้
        ลูกค้าควรทราบว่าบริการ SMS อีเมลอาจถูกปลอมแปลงหรือล้วงข้อมูลได้ ดังนั้น
        ลูกค้าควรใช้ความระมัดระวัง
        ในการดูข้อความที่ส่งมาว่ามาจากบริษัทจริงหรือไม่
        โปรดล็อกอินเข้าสู่ระบบผ่านเว็บไซต์และแอปพลิเคชันของบริษัท
        เพื่อตรวจสอบธุรกรรม หรือดำเนินการใด
        หากลูกค้าไม่แน่ใจเกี่ยวกับความถูกต้องแม่นยำในการสื่อสาร
        หรือการแจ้งเตือนจากบริษัท
      </div>
      <div className="text-lg font-bold">
        4.4 ความรับผิดชอบและชดใช้ค่าเสียหาย
      </div>
      <div className="mt-3">
        (1) หากลูกค้ามีข้อพิพาทกับผู้ใช้งานอื่น
        หรือบุคคลภายนอกจากการบริการของบริษัท
        ลูกค้าตกลงและยินยอมที่จะไม่เรียกร้องหรือเรียกให้บริษัทต้องรับผิด
        อันเกิดจากข้อพิพาทที่เกิดขึ้นดังกล่าว
        ไม่ว่าลักษณะใดและประเภทใดที่เกิดขึ้นเกี่ยวข้องกับกรณีพิพาทนั้น
      </div>
      <div className="mt-3">
        (2) ลูกค้ายินยอมที่จะชดใช้และปกป้อง บริษัทจากข้อเรียกร้องและการฟ้องร้อง
        (รวมถึง ค่าทนายความ และ ค่าปรับ
        ค่าธรรมเนียมลงโทษจากหน่วยงานผู้ออกกฎระเบียบ)
        ซึ่งเกิดขึ้นหรือเกี่ยวข้องกับการฝ่าฝืนข้อตกลงฉบับนี้
        รวมถึงข้อตกลงที่แก้ไขที่เกิดขึ้น หรือการละเมิดกฎหมาย กฎ
        หรือระเบียบของลูกค้า รวมถึงการละเมิดสิทธิของบุคคลภายนอก
      </div>
      <div className="text-lg font-bold">4.5 การจำกัดความรับผิด</div>
      <div className="mt-3">
        ลูกค้าตกลงและยินยอมว่า บริษัทจะไม่ต้องรับผิดใด
        สำหรับความเสียหายจากการขาดทุน หรือค่าเสียหายกรณีพิเศษ ค่าเสียหายทางอ้อม
        ค่าเสียหายที่จับต้องไม่ได้ หรือค่าเสียหายต่อเนื่องอื่น
        ไม่ว่าจะเป็นมูลเหตุจากการทำละเมิด หรือการกระทำอื่นใดที่เกิดจาก
        หรือเกี่ยวข้องกับการใช้งานที่ได้รับอนุญาต
        หรือไม่ได้รับอนุญาตบนเว็บไซต์และแอปพลิเคชัน
        หรือการใช้บริการของบริษัทที่ไม่ถูกต้อง
        หรือการละเมิดข้อตกลงฉบับนี้ของลูกค้า
        เว้นแต่จะเป็นกรณีที่เกิดจากความผิดพลาดในการให้บริการของบริษัท
      </div>
      <div className="text-lg font-bold">4.6 ข้อตกลงทั้งหมด</div>
      <div className="mt-3">
        ข้อตกลงฉบับนี้ และเอกสารแนบท้ายข้อตกลงฉบับนี้
        ประกอบเป็นข้อตกลงและความเข้าใจทั้งหมดที่ถูกต้องตรงกันระหว่างบริษัทและลูกค้าในส่วนที่ระบุไว้ในข้อตกลงฉบับนี้และจะมีผลบังคับใช้
        การปรึกษาหารือ การตกลง และความเข้าใจ ไม่ว่าลักษณะใด เช่น ข้อตกลงฉบับก่อน
        ทั้งนี้ หัวข้อในแต่ละส่วนของข้อตกลงฉบับนี้
        ถูกกำหนดขึ้นเพื่อจุดประสงค์ในการสื่อสารเท่านั้น
        และจะไม่มีผลบังคับเหนือการตีความข้อกำหนดในแต่ละหัวข้อดังกล่าว
      </div>
      <div className="text-lg font-bold">4.7 การแก้ไขเพิ่มเติม</div>
      <div className="mt-3">
        (1) บริษัทอาจใช้ดุลยพินิจแต่เพียงผู้เดียว
        และไม่ต้องแจ้งให้ทราบล่วงหน้าในการแก้ไขหรือปรับเปลี่ยน ข้อตกลงฉบับนี้
        และเอกสารแนบท้ายนี้ ทั้งนี้ บริษัทจะคำนึงถึงผลประโยชน์ของลูกค้าเป็นสำคัญ
        โดยจะมีการเผยแพร่การแก้ไขผ่านเว็บไซต์และแอปพลิเคชันของบริษัท
        หรือส่งอีเมลถึงลูกค้า การเปลี่ยนแปลงแก้ไขดังกล่าว
        จะมีผลบังคับสมบูรณ์เมื่อมีการลงนามหรือยอมรับเป็นลายลักษณ์อักษรระหว่างบริษัทและลูกค้า
        โดยลูกค้าตกลงและยินยอมที่จะปฏิบัติตามเงื่อนไขที่บริษัทกำหนดอย่างเคร่งครัด
      </div>
      <div className="mt-3">
        (2) หากลูกค้าไม่ได้ตกลงและยินยอมในการแก้ไขเพิ่มเติมใด
        ลูกค้ามีสิทธิในการยกเลิกการใช้บริการของบริษัทและปิดบัญชีผู้ใช้
      </div>
      <div className="mt-3">
        (3) ลูกค้ายินยอมว่า บริษัทจะไม่ต้องรับผิดต่อลูกค้าหรือบุคคลภายนอก
        สำหรับการปรับเปลี่ยนหรือการยุติการบริการของบริษัท
        รวมถึงการระงับชั่วคราวและการยุติการเข้าถึงบัญชีของลูกค้า
        เว้นแต่มีการกำหนดไว้เป็นอย่างอื่นเป็นการเฉพาะ
      </div>
      <div className="text-lg font-bold">4.8 การโอนสิทธิและหน้าที่</div>
      <div className="mt-3">
        (1) ลูกค้าต้องไม่โอนสิทธิหรือหน้าที่ภายใต้ข้อตกลงฉบับนี้
        และที่เกี่ยวข้องกับการบริการของบริษัท ไม่ว่าในกรณีใด
        โดยไม่ได้รับความยินยอมเป็นลายลักษณ์อักษรเป็นการเฉพาะจากบริษัท
      </div>
      <div className="mt-3">
        (2) บริษัทสงวนสิทธิ์ที่จะโอนสิทธิของบริษัทได้ โดยปราศจากข้อจำกัด เช่น
        การโอนสิทธิให้แก่บริษัทในเครือ หรือผู้รับโอนอื่น ๆ
        ซึ่งมีผลประโยชน์ได้เสียกับธุรกิจและบริการของบริษัท
      </div>
      <div className="mt-3">
        (3) ในกรณีใดที่บริษัทได้เข้าสู่การควบรวมกิจการกับบุคคลภายนอก
        บริษัทสงวนสิทธิ์ในกรณีใด ๆ ในการโอน
        หรือมอบข้อมูลที่บริษัทได้เก็บรวบรวมจากลูกค้า
        หรือบุคคลภายนอกที่จำเป็นสำหรับการควบกิจการ หรือการซื้อขาย
        เปลี่ยนแปลงการควบคุมบริษัทดังกล่าว
      </div>
      <div className="mt-3">
        (4) ความพยายามในการโอนหรือมอบสิทธิ ที่เป็นการละเมิดข้อกำหนดนี้
        ที่เป็นโมฆะและไม่มีผลบังคับ
      </div>
      <div className="mt-3">
        (5) ภายใต้เงื่อนไขที่ระบุไว้ข้างต้น ข้อตกลงฉบับนี้จะมีผลผูกพันต่อเนื่อง
        เพื่อประโยชน์ของคู่สัญญาที่ระบุไว้ในข้อตกลงและผู้รับโอนสิทธิที่ได้รับอนุญาต
      </div>
      <div className="text-lg font-bold">4.9 การเป็นโมฆะบางส่วน</div>
      <div className="mt-3">
        หากบทบัญญัติใด ๆ ในข้อตกลงฉบับนี้ ได้รับการพิจารณาว่า ไม่สมบูรณ์
        หรือไม่มีผลบังคับ ภายใต้ระเบียบกฎหมาย หรือกฎใด ๆ หรือโดยหน่วยงานรัฐ
        หน่วยงานท้องถิ่น มลรัฐ หรือ รัฐบาลกลางใด
        ให้ถือว่าข้อกำหนดดังกล่าวจะถูกแก้ไขและตีความไปในทางที่มีผลใช้บังคับอยู่ได้มากที่สุดเท่าที่กฎหมายที่เกี่ยวข้องอนุญาต
        และการเป็นโมฆะของบางบทบัญญัติดังกล่าว จะไม่กระทบกับความสมบูรณ์
        หรือการบังคับใช้ข้อกำหนดอื่น ๆ ของข้อตกลงฉบับนี้และบทบัญญัติอื่น ๆ
      </div>
      <div className="text-lg font-bold">
        4.10 ข้อตกลงที่ให้มีผลบังคับต่อไปแม้การยกเลิกข้อตกลง
      </div>
      <div className="mt-3">
        ข้อตกลงฉบับนี้ ซึ่งโดยลักษณะมีผลบังคับไปภายหลังการยกเลิกข้อตกลงฉบับนี้
        เช่น บทบัญญัติ เกี่ยวกับการระงับใช้ หรือการยกเลิกบัญชีผู้ใช้
        หรือหนี้สินที่ลูกค้ามีต่อบริษัท
        การใช้เว็บไซต์และแอปพลิเคชันของบริษัทเป็นการทั่วไป ข้อพิพาท
        และบทบัญญัติทั่วไป จะยังคงมีผลบังคับต่อไป
        แม้ว่าข้อตกลงฉบับนี้จะสิ้นสุดและยกเลิกไปแล้ว
      </div>
      <div className="text-lg font-bold">4.11 การบอกเลิกข้อตกลง</div>
      <div className="mt-3">
        (1) ข้อตกลงนี้ไม่มีกำหนดระยะเวลา
        และให้ถือว่าข้อตกลงนี้มีผลบังคับใช้ต่อไปจนกว่าจะได้มีการบอกเลิกสัญญาโดยฝ่ายใดฝ่ายหนึ่ง
      </div>
      <div className="mt-3">
        (2) ลูกค้าจะบอกเลิกข้อตกลงได้ก็ต่อเมื่อปรากฏว่าลูกค้าไม่มีหนี้สินใด ๆ
        ค้างชำระกับบริษัท การบอกเลิกข้อตกลงในกรณีนี้
        ลูกค้าจะต้องแจ้งเป็นหนังสือให้บริษัททราบ ถ้าปรากฏว่าลูกค้าไม่มีหนี้สินใด
        ๆ
        ค้างชำระกับบริษัทก็ให้ข้อตกลงมีผลเลิกกันในวันทำการที่เจ็ดถัดจากวันที่บริษัทได้รับหนังสือดังกล่าว
        อนึ่ง ตั้งแต่วันที่บริษัทได้รับหนังสือดังกล่าวเป็นต้นไป
        ลูกค้าตกลงยินยอมให้บริษัทมีสิทธิใช้ดุลยพินิจที่จะสั่งพัก
        หรือระงับการซื้อขายสินทรัพย์ดิจิทัลของลูกค้าได้
        โดยลูกค้าจะไม่เรียกร้องค่าเสียหายใด ๆ จากบริษัท
      </div>
      <div className="mt-3">
        (3)
        หากลูกค้ามิได้ทำการติดต่อซื้อขายสินทรัพย์ดิจิทัลกับบริษัทเป็นระยะเวลาที่บริษัทพิจารณาเห็นสมควร
        และ/หรือลูกค้าไม่มีภาระหนี้ใด ๆ ค้างชำระกับบริษัท
        และ/หรือไม่มีทรัพย์สินใด ๆ คงเหลืออยู่กับบริษัท
        ลูกค้าตกลงยินยอมให้บริษัทใช้ดุลยพินิจที่จะสั่งพัก
        และ/หรือปิดบัญชีของลูกค้าได้ ในกรณีที่บริษัทยังไม่ปิดบัญชีของลูกค้า
        บริษัทมีสิทธิเรียกให้ลูกค้าชำระค่ารักษาบัญชีเป็นจำนวนเงินและภายในระยะเวลาที่บริษัทกำหนด
      </div>
      <div className="mt-3">
        (4) ในกรณีที่บริษัทเป็นฝ่ายบอกเลิกข้อตกลงกับลูกค้า
        บริษัทจะแจ้งให้ลูกค้าทราบเป็นลายลักษณ์อักษรและลูกค้าตกลงยินยอมที่จะชำระหนี้ทุกชนิดของลูกค้า
        รวมทั้งดอกเบี้ยและค่าแห่งอุปกรณ์แห่งหนี้ให้แก่บริษัทภายในระยะเวลาที่บริษัทจะแจ้งให้ทราบ
      </div>
      <div className="text-lg font-bold">
        4.12 กฎหมายที่บังคับใช้ และการระงับข้อพิพาท
      </div>
      <div className="mt-3">
        ข้อตกลงฉบับนี้ให้อยู่ภายใต้บังคับและการตีความตามกฎหมายไทย
        ในกรณีที่มีข้อพิพาททางแพ่งที่เกี่ยวกับ
        หรือเนื่องจากการประกอบธุรกิจศูนย์ซื้อขายสินทรัพย์ดิจิทัล
        ลูกค้าอาจขอให้มีการระงับข้อพิพาทโดยอนุญาโตตุลาการของไทย
        โดยบริษัทยินดีเข้าสู่กระบวนการอนุญาโตตุลาการตามที่ลูกค้าร้องขอ
      </div>
      <div className="text-lg font-bold">4.13 เหตุวิสัย</div>
      <div className="mt-3">
        บริษัทไม่ต้องรับผิดชอบต่อความล่าช้า หรือความบกพร่องในการดำเนินการ
        หรือการหยุดชะงักของบริการของบริษัท
        ซึ่งเกิดขึ้นโดยตรงหรือทางอ้อมจากเหตุปัจจัยที่เหนือความควบคุมอย่างสมเหตุสมผล
        เช่น ความล่าช้า หรือ ความบกพร่อง อันเนื่องมาจากภัยธรรมชาติ
        อำนาจทางพลเรือนหรือทหาร การก่อการร้าย การก่อความไม่สงบ สงคราม
        การนัดหยุดงาน หรือ เหตุพิพาทด้านแรงงานอื่น อัคคีภัย น้ำท่วม
        ความขัดข้องของระบบโทรคมนาคม หรือบริการอินเทอร์เน็ต
        หรือผู้ให้บริการเครือข่าย ความบกพร่องของอุปกรณ์ และ/หรือ ซอฟต์แวร์
        หรือภัยพิบัติ
        หรือปรากฏการณ์อื่นที่เหนือความควบคุมทางพาณิชย์ของบริษัทและเหตุสุดวิสัยทั้งหมด
        จะต้องไม่ส่งผลต่อการบังคับใช้และการปฏิบัติตามข้อตกลงอื่น ๆ
      </div>
      <div className="text-lg font-bold">
        ภาคผนวก 1: การใช้งานต้องห้าม และ ธุรกิจที่ต้องห้าม
      </div>
      <div className="text-lg font-bold">การใช้งานต้องห้าม</div>
      <div className="mt-3">
        ลูกค้าไม่สามารถใช้บัญชีผู้ใช้ในการดำเนินการประเภทดังต่อไปนี้
        ("การใช้งานต้องห้าม") ทั้งนี้ การใช้งานที่ระบุไว้
        เป็นเพียงตัวอย่างเท่านั้น
        และด้วยการเปิดบัญชีผู้ใช้บริการลูกค้ารับประกันว่า
        ลูกค้าจะไม่ใช้บัญชีผู้ใช้ของลูกค้ากระทำการ ดังต่อไปนี้:
      </div>
      <div className="mt-3">
        <b>กิจกรรมที่ผิดกฎหมาย:</b> กิจกรรมที่อาจละเมิด
        หรืออาจถูกพิจารณาว่าละเมิด หรือมีส่วนช่วยในการละเมิดกฎหมาย กฎ ข้อบัญญัติ
        หรือระเบียบข้อบังคับใด ๆ ที่บังคับใช้ในประเทศไทย
        หรือภายใต้กฎหมายประเทศที่บริษัทดำเนินธุรกิจ หรือ
        กิจกรรมที่อาจเกี่ยวข้องกับกิจกรรมผิดกฎหมาย ในลักษณะอื่น
        หรือกิจกรรมที่เกี่ยวข้องกับการโฆษณา
        หรือการเผยแพร่วัสดุหรือข้อมูลที่ผิดกฎหมาย
      </div>
      <div className="mt-3">
        <b>กิจกรรมที่ไม่ถูกต้อง:</b>
        การกระทำที่ก่อให้เกิดการถ่ายโอนข้อมูลในปริมาณที่มากเกินสมควร
        หรือที่ไม่ได้สัดส่วน หรือการกระทำที่อาจส่งผลกระทบด้านลบอย่างร้ายแรง
        รวมถึงขัดขวาง หรือยึดระบบข้อมูลหรือข้อมูลต่าง ๆ รวมไปถึง การส่ง
        หรือถ่ายโอนข้อมูลบนเว็บไซต์และแอปพลิเคชันของบริษัท
        ซึ่งอาจมีไวรัสคอมพิวเตอร์ โทรจัน เวิร์ม
        หรือโปรแกรมคอมพิวเตอร์ซึ่งเป็นอันตรายหรือเป็นภัย
        หรือการพยายามที่จะเข้าถึงเว็บไซต์และแอปพลิเคชันอื่น บัญชีผู้ใช้ของลูกค้า
        ระบบคอมพิวเตอร์
        หรือเครือข่ายที่เชื่อมต่อกับเว็บไซต์และแอปพลิเคชันของบริษัท
        โดยไม่ได้รับอนุญาตผ่านการพยายามหารหัสผ่าน หรือวิธีการอื่น
        หรือการใช้ข้อมูลบัญชีผู้ใช้ของผู้อื่น
        เพื่อเข้าถึงหรือใช้เว็บไซต์และแอปพลิเคชันของบริษัท
        หรือโอนสิทธิการเข้าถึงบัญชีผู้ใช้ของลูกค้า
        หรือโอนสิทธิในบัญชีผู้ใช้ของลูกค้าให้กับบุคคลภายนอก
        เว้นแต่เป็นการดำเนินการตามกฎหมายที่เกี่ยวข้อง
        หรือได้รับอนุญาตโดยชัดแจ้งจากบริษัท
      </div>
      <div className="mt-3">
        <b>การใช้สิทธิที่กระทบสิทธิของผู้ใช้อื่น:</b>
        การรบกวนการเข้าสู่ระบบของบุคคลอื่นหรือขององค์กรอื่น
        ในการใช้บริการของบริษัท การหมิ่นประมาท ข่มเหง ขู่เข็ญ ข่มขู่ เฝ้าติดตาม
        คุกคาม หรือ กระทำการใดที่เป็นการละเมิด หรือฝ่าฝืนสิทธิทางกฎหมาย (เช่น
        สิทธิในความเป็นส่วนตัว สิทธิในการเผยแพร่ข้อมูล และ
        สิทธิในทรัพย์สินทางปัญญา) ของผู้อื่น รวมถึง ดำเนินการยั่วยุ ข่มขู่
        กระตุ้น สนับสนุน หรือ ส่งเสริมความเกลียดชัง
        การไม่ยอมรับความแตกต่างในเชื้อชาติ หรือการกระทำรุนแรงต่อผู้อื่น เก็บ
        หรือ ทำการอื่นใด ในการรวบรวมข้อมูลจากเว็บไซต์และแอปพลิเคชันของบริษัท
        อันเกี่ยวกับบุคคลอื่น เช่น ที่อยู่อีเมล โดยไม่ได้รับอนุญาตอย่างถูกต้อง
      </div>
      <div className="mt-3">
        <b>การฉ้อโกง:</b> กิจกรรมที่ก่อให้เกิดการหลอกลวงบริษัท
        ผู้ใช้อื่นที่ใช้เว็บไซต์และแอปพลิเคชันของบริษัทหรือบุคคลอื่นใด
        หรือให้ข้อมูลที่ผิด หรือไม่ถูกต้อง
        หรือข้อมูลที่สร้างความเข้าใจผิดแก่บริษัท
      </div>
      <div className="mt-3">
        <b>การพนัน:</b> การซื้อสลากกินรวบ การประมูลแบบต้องเสียเงินค่าธรรมเนียม
        การทายผลกีฬา หรือ การพนัน เกมกีฬาแฟนตาซีที่มีเงินเป็นรางวัล
        เกมทางอินเตอร์เน็ต การประกวด การชิงโชค การละเล่นที่เสี่ยงโชค
      </div>
      <div className="mt-3">
        <b>การละเมิดทรัพย์สินทางปัญญา:</b> การกระทำ เกี่ยวข้องกับ
        ธุรกรรมที่ฝ่าฝืน หรือ ละเมิดลิขสิทธิ์ เครื่องหมายการค้า
        สิทธิในการทำให้ปรากฏต่อสาธารณะ สิทธิในความเป็นส่วนตัว การรักษาความลับ
        หรือ สิทธิในการเป็นเจ้าของทรัพย์สิน ภายใต้กฎหมาย เช่น การขาย การจำหน่าย
        หรือ การเข้าถึงเพลง ภาพยนตร์ ซอฟต์แวร์ที่ละเมิดลิขสิทธิ์ หรือวัตถุใด ๆ
        ซึ่งต้องได้รับอนุญาตจากเจ้าของสิทธิ แต่ไม่ได้รับการ
        อนุญาตที่ถูกต้องจากเจ้าของสิทธิ หรือการใช้ทรัพย์สินทางปัญญา ชื่อ หรือ
        เครื่องหมาย รวมถึง การใช้เครื่องหมายการค้าหรือเครื่องหมายบริการของบริษัท
        โดยไม่ได้รับการอนุญาตชัดแจ้ง เป็นลายลักษณ์ อักษรจากบริษัท หรือ
        จากการกระทำใด ๆ หรือ การกระทำที่อาจตีความเป็นนัยให้เข้าใจผิดได้ว่า
        ได้รับความ ยินยอมจากบริษัท
      </div>
      <div className="text-lg font-bold">ธุรกิจต้องห้าม</div>
      <div className="mt-3">
        นอกจากการใช้งานต้องห้าม ที่ได้กล่าวมาข้างต้นแล้ว
        ประเภทของธุรกิจดังต่อไปนี้ก็ถือว่าต้องห้าม
        และลูกค้าต้องไม่ดำเนินธุรกิจจากการใช้บริการของบริษัท (“ธุรกิจต้องห้าม”)
        โดยการเปิดบัญชีผู้ใช้ของลูกค้า ลูกค้าต้องไม่ใช้บริการของบริษัทกับธุรกิจ
        กิจกรรม วิธีปฏิบัติ หรือรายการ ดังต่อไปนี้
      </div>
      <div className="mt-3">
        <b>การบริการลงทุน และ บริการเครดิต:</b> นายหน้าซื้อขายหลักทรัพย์
        ที่ปรึกษาด้านการจำนอง หรือ บริการลดหนี้
        ที่ปรึกษาในด้านเครดิตหรือการแก้ไขเครดิต
        ที่ปรึกษาโอกาสทางด้านอสังหาริมทรัพย์และแผนการลงทุน
      </div>
      <div className="mt-3">
        <b>การบริการทางการเงินที่มีข้อจำกัด:</b> เช็คเงินสด เงินประกัน
        สำนักงานทวงหนี้
      </div>
      <div className="mt-3">
        <b>การละเมิดทรัพย์สินทางปัญญาหรือสิทธิในการเป็นเจ้าของทรัพย์สิน:</b>{" "}
        การขาย การจำหน่าย หรือ การเข้าถึงเพลง ภาพยนตร์ ซอฟต์แวร์ หรือ
        สิ่งอื่นใดซึ่ง ต้องมีใบอนุญาตในการใช้ แต่ไม่ได้รับ
        การอนุญาตจากเจ้าของสิทธิที่ถูกต้อง
      </div>
      <div className="mt-3">
        <b>สินค้าปลอมหรือสินค้าที่ไม่ได้รับอนุญาต:</b> การขาย หรือ การขายซ้ำ
        โดยไม่ได้รับอนุญาต ในสินค้า ที่มียี่ห้อ หรือ สินค้า หรือ
        บริการของดีไซน์เนอร์นักออกแบบ การขายสินค้า หรือ บริการ ที่นำเข้า หรือ
        ส่งออกผิดกฎหมาย หรือที่ถูกขโมยมา
      </div>
      <div className="mt-3">
        <b>สินค้าและบริการที่ควบคุม:</b>{" "}
        จำหน่ายและประกอบธุรกิจที่เกี่ยวข้องกับกัญชา การขายบุหรี่ บุหรี่ไฟฟ้า
        น้ำยาบุหรี่ไฟฟ้า ใบสั่งยาออนไลน์ หรือบริการทางเภสัชกรรม
        สินค้าหรือบริการที่มีการควบคุมอาวุธและสิ่งที่ใช้ต่อสู้ ดินปืน
        และระเบิดพลุสินค้าที่เกี่ยวข้อง วัสดุที่เป็นพิษติดไฟง่าย
        และวัสดุเกี่ยวกับกัมมันตภาพรังสี
      </div>
      <div className="mt-3">
        <b>ยาและอุปกรณ์ที่เกี่ยวกับยา:</b> การขายยาเสพติด,สารควบคุม
        และอุปกรณ์ที่ออกแบบมาเพื่อการผลิตยา หรือการใช้ยา เช่น บ้องกัญชา สารระเหย
        หม้อสูบยา
      </div>
      <div className="mt-3">
        <b>เภสัชภัณฑ์เทียม:</b>{" "}
        สินค้าเภสัชกรรมและสินค้าที่ทำให้เกิดปัญหาด้านสุขภาพที่ไม่ได้รับการรับรอง
        หรือผ่านการตรวจสอบจากหน่วยงานท้องถิ่นที่เกี่ยวข้อง
        และ/หรือหน่วยงานระดับชาติ และ/หรือ หน่วยงานด้านกฎระเบียบระหว่างประเทศ
      </div>
      <div className="mt-3">
        <b>สารที่มีผลเหมือนยาเสพติดที่ผิดกฎหมาย:</b>{" "}
        การขายสารที่ถูกกฎหมายแต่ให้ผลเหมือนยาเสพติดที่ผิดกฎหมาย (เช่น แซลเวีย
        และใบกระท่อม)
      </div>
      <div className="mt-3">
        <b>บริการและรายการที่มีเนื้อหาสำหรับผู้ใหญ่:</b> สื่อลามก
        หรือสิ่งลามกอนาจาร (รวมไปถึง สิ่งพิมพ์ รูปภาพ สื่อต่าง ๆ)
        เว็บไซต์และแอปพลิเคชันที่มีการเสนอบริการเกี่ยวกับทางเพศ เช่น
        การค้าประเวณี หาเพื่อนเที่ยว การจ่ายเงินตามยอดการเข้าชม
        และการแชทสดผ่านทางเครือข่ายอินเตอร์เน็ตสำหรับผู้ใหญ่
      </div>
      <div className="mt-3">
        <b>ธุรกิจเครือข่าย:</b> การทำธุรกิจแบบพีระมิด การตลาดผ่านเครือข่าย
        และการตลาดแบบแนะนำบอกต่อ
      </div>
      <div className="mt-3">
        <b>การกระทำที่ไม่เป็นธรรม จำกัดคู่แข่ง หรือที่หลอกลวง:</b>{" "}
        โอกาสในการลงทุนหรือบริการที่สัญญาว่าจะให้ผลตอบแทนสูง การขาย
        หรือการขายซ้ำซึ่งบริการโดยไม่มีการให้ผลประโยชน์เพิ่มเติมแก่ผู้ซื้อ
        การขายซ้ำข้อเสนอของรัฐบาล โดยไม่ได้รับอนุญาตหรือมีการเพิ่มราคาเท่าไป
        หรือกรณีที่เราใช้ดุลยพินิจของเราแต่เพียงผู้เดียวโดยเห็นว่าเป็นการกระทำที่ไม่เป็นธรรม
        หลอกลวง หรือเป็นการจำกัดคู่แข่งซึ่งส่งผลต่อผู้บริโภค
      </div>
      <div className="mt-3">
        <b>ธุรกิจที่มีความเสี่ยงสูง:</b>{" "}
        ธุรกิจที่เราเชื่อว่าเป็นต้นเหตุให้มีความเสี่ยงทางการเงินสูงขึ้น
        ทำให้เกิดความรับผิดทางกฎหมาย หรือละเมิดนโยบายของธนาคาร
      </div>
      <div className="text-lg font-bold">การใช้งานที่มีเงื่อนไข</div>
      <div className="mt-3">
        ท่านต้องได้รับความยินยอมอย่างชัดเจนเป็นลายลักษณ์อักษรจากเรา
        ก่อนที่ท่านจะใช้งานหรือธุรกิจดังต่อไปนี้ได้ (“การใช้งานที่มีเงื่อนไข”)
      </div>
      <div className="mt-3">
        ท่านสามารถร้องขอความยินยอมจากบริษัทได้โดยการติดต่อผ่านทาง
        yaks.club@gmail.com หรือตามช่องทางที่บริษัทกำหนด
        โดยหากมีการเปลี่ยนแปลงในอนาคต บริษัทขอสงวนสิทธิ์ที่จะไม่แจ้งล่วงหน้า
        โดยจะประกาศบนเว็บไซต์และแอปพลิเคชันของบริษัทต่อไป
        ซึ่งบริษัทสามารถเรียกร้องให้ท่านเห็นชอบกับเงื่อนไขเพิ่มเติม
        ให้การรับรองหรือรับประกันเพิ่มเติม หรือดำเนินการใช้งานภายใต้ข้อจำกัด
        ถ้าท่านใช้บริการของบริษัทที่เกี่ยวข้องกับธุรกิจ กิจกรรม
        และระเบียบปฏิบัติดังต่อไปนี้
      </div>
      <div className="mt-3">
        <b>บริการทางการเงิน:</b>{" "}
        การโอนเงินทั้งสกุลเงินตามกฎหมายและสกุลเงินดิจิตอล
        การค้าหรือแลกเปลี่ยนสกุลเงิน หรือสกุลเงินดิจิตอล การค้า
        หรือการแลกเปลี่ยนสกุลเงินหรือสกุลเงินดิจิตอล บัตรของขวัญ
        บัตรที่มีการชำระล่วงหน้า การขายสกุลเงินในเกม
        ถ้าผู้ขายไม่ได้ให้บริการในโลกเสมือนจริง
        หรือการแสดงตนเป็นสื่อกลางในการชำระเงิน หรือตัวกลางในการจัดหา
        หรือกระทำการอื่น ซึ่งเป็นการขายซ้ำ การบริการของบริษัทใด ๆ
      </div>
      <div className="mt-3">
        <b>การกุศล:</b> การรับบริจาคสำหรับองค์กรที่ไม่แสวงผลกำไร
      </div>
      <div className="mt-3">
        <b>เกมทักษะ:</b> เกมที่ไม่จัดเป็นการพนันภายใต้ข้อตกลงฉบับนี้
        หรือตามกฎหมายที่เกี่ยวข้อง แต่มีการเก็บค่าแรกเข้า และมีการให้รางวัล
      </div>
      <div className="mt-3">
        <b>องค์กรด้านศาสนา และจิตวิญญาณ:</b> การดำเนินการขององค์กรด้านศาสนา
        หรือจิตวิญญาณที่มีการแสวงหาผลกำไร
      </div>
    </div>
  );
};

export default TermAndCondition;
