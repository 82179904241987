const Policy = () => {
  return (
    <div className="mx-5 xl:mx-28 mb-28">
      <div className="text-lg font-bold">
        Marketing Consent and Privacy Policy for YAKS x Amazing Thailand NFTs Application
      </div>
      <p className="mt-5">
      Drive Digital Co., Ltd. respects and recognizes the importance of protecting your personal data. Therefore, we have established this Privacy Policy to inform you of the Company's policy regarding the collection, use, and disclosure of personal data (referred to as "personal data processing") as follows:
      </p>
      <div className="mt-3 text-m font-bold">
        1. Personal data that the company collects, uses, and discloses
      </div>
      <div className="mt-3">
        The company collects, uses, and discloses various types of personal data, which can be categorized as follows:
      </div>
      <ul className="mt-3">
        <li>(a) Identity verification data, such as email and phone number. </li>
        <li>
          (b) System access data, such as date and time of access, IP address, log, device ID, device model and type, network, connection information, login log, access time, time spent on the company's website, cookies, login information, search history, view data, browser type and version, time zone setting, and location. Also, plug-in type and version, operating system and platform, and other technologies on the device used to access the platform, and so on.
        </li>
      </ul>
      <div className="mt-3 text-m font-bold">2. Source of Data</div>
      <p className="mt-3">
        The company collects personal data from various sources for the purposes of complying with contracts, laws, regulations, internal rules of the company, and relevant regulatory agencies, as well as for the necessary and legitimate interests of the company and external persons or entities who have a vested interest. The company collects personal data from various sources, including:
      </p>
      <li>Personal data obtained directly from the data subject.</li>
      <li>Personal data obtained from affiliated companies.</li>
      <li>
        Personal data obtained from third parties such as agents, merchants, or companies that provide data collection services, partners, and affiliates.
      </li>
      <li>
        Personal data obtained from government agencies and regulatory agencies authorized by law.
      </li>
      <div className="mt-3 text-m font-bold">
        3. Collection, use, and disclosure of personal data
      </div>
      <p>
        The company collects, uses, and discloses personal data based on legal bases or exemptions under the Personal Data Protection Act of 2019. This includes for the purpose of fulfilling contracts, complying with laws, regulations, and orders from government agencies or regulatory bodies, for the benefit of the company, external individuals or entities, or for important public interests and interests of life and body.
      </p>
      <div>
        The company may disclose or transfer your personal data to the following entities:
      </div>
      <div className="mt-3 text-m font-bold">1. Group companies</div>
      <p>
        The company may disclose or transfer personal data to group companies only if you have given consent to the company to disclose personal data to the group companies, or if it is necessary to perform a contract between the company and you, or to achieve other objectives as set forth in this Privacy Policy.
      </p>
      <div className="mt-3 text-m font-bold">2. Service providers of the company</div>
      <p>
        The company may use agents or contractors to provide various services on behalf of the company or to assist in obtaining products and services for you.
      </p>
      <div className="mt-3 text-m font-bold">
        3. Other organizations, government agencies, or third parties
      </div>
      <p>
        The company may disclose or transfer your personal data to government agencies, regulatory bodies, or other organizations or third parties in order to process personal data as set forth in this Privacy Policy, or in case of necessity as required by laws, orders, or regulations from supervisory authorities, government agencies, or other organizations.
      </p>
      <p className="mt-3">
        The company will take reasonable steps to ensure that your personal data is disclosed, transferred, or forwarded only to the extent necessary.
      </p>
      <table class="table-fixed border-2">
        <thead>
          <tr>
            <th className="w-1/4 border-2">Purpose of Processing</th>
            <th className="w-1/4 border-2">Type of Data</th>
            <th className="w-1/4 border-2">Legal Basis and Exceptions</th>
            <th className="w-1/4 border-2">
              Disclosure/Transmission to Third Party Organizations or Individuals
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border-2 ">
              To be used as customer identity verification data prior to providing services requested by the customer
            </td>
            <td className="border-2 ">
              <div>(a) Data for identity verification</div>
              <div>(b) Data for customer contact</div>
              <div>(c) System access data</div>
              <div>(d) Transaction history data</div>
            </td>
            <td className="border-2 ">
              <div>General personal data</div>
              <div>- For contract performance</div>
              <div>- For compliance with laws</div>
              <div>Sensitive personal data (biometric data)</div>
              <div>- Necessary for compliance with the law for important public interests</div>
            </td>
            <td className="border-2 ">
              Company contracted to provide customer service
            </td>
          </tr>
          <tr>
            <td className="h-40 border-2">
              To be used as data for contacting customers to offer services and send market news information unrelated to digital assets
            </td>
            <td className="border-2 ">
              (d) Data for subscribing to various newsletters from surveys or data from various activities
            </td>
            <td className="border-2 ">Consent obtained</td>
            <td className="border-2 ">None</td>
          </tr>
          <tr>
            <td className="h-40 border-2">
              To be used as data for contacting customers to offer services and send market news related to digital assets
            </td>
            <td className="border-2 ">
              <div>
                (e) Data for registration or filling out applications to participate in various activities of the company
              </div>
              <div>
                (f) Data for subscribing to various newsletters from surveys or data from various activities
              </div>
            </td>
            <td className="border-2 ">For legitimate interests</td>
            <td className="border-2 ">None</td>
          </tr>
          <tr>
            <td className="h-40 border-2">
              To review customers' transaction history over a specified period to adjust risk levels and appropriate customer credit limits
            </td>
            <td className="border-2 ">(d) Transaction history data</td>
            <td className="border-2 ">
              <div>- For contract performance</div>
              <div>- For compliance with laws</div>
            </td>
            <td className="border-2 ">None</td>
          </tr>
          <tr>
            <td className="h-40 border-2">
              To review customers' transaction history over a specified period to adjust risk levels and appropriate customer credit limits
            </td>
            <td className="border-2 ">(d) Transaction history data</td>
            <td className="border-2 ">
              <div>- For contract performance</div>
              <div>- For compliance with laws</div>
            </td>
            <td className="border-2 ">None</td>
          </tr>
          <tr>
            <td className="h-40 border-2">
              To conduct surveys on customer experiences and usage for customers
            </td>
            <td className="border-2 ">
              (g) Data from personal contact records of the data subject with the company
            </td>
            <td className="border-2 ">Consent obtained</td>
            <td className="border-2 ">None</td>
          </tr>
          <tr>
            <td className="h-40 border-2">
              To review customers' transaction history over a specified period to adjust risk levels and appropriate customer credit limits
            </td>
            <td className="border-2 ">(d) Transaction history data</td>
            <td className="border-2 ">
              <div>- For contract performance</div>
              <div>- For compliance with laws</div>
            </td>
            <td className="border-2 ">None</td>
          </tr>
          <tr>
            <td className="h-40 border-2">
              Processing for profiling, analysis, and statistics to predict individuals for the company and its affiliated companies.
            </td>
            <td className="border-2 ">
              <div>(a) Data for identity verification</div>
              <div>(c) Data from personal communication records with the company owner, which</div>
              <div>(g) Transaction history data</div>
            </td>
            <td className="border-2 ">Consent obtained</td>
            <td className="border-2 ">N/A</td>
          </tr>
          <tr>
            <td className="h-40 border-2">
              Analysis, research, and statistics to improve the company's products.
            </td>
            <td className="border-2 ">
              <div>(a) Data for identity verification</div>
              <div>(c) Data from personal communication records with the company owner, which</div>
              <div>(g) Transaction history data</div>
            </td>
            <td className="border-2 ">For legitimate interests</td>
            <td className="border-2 ">N/A</td>
          </tr>
          <tr>
            <td className="h-40 border-2">
              Sending customer data to affiliated companies.
            </td>
            <td className="border-2 ">
              <div>(a) Data for identity verification (name-surname only)</div>
              <div>(b) Data for customer contact</div>
            </td>
            <td className="border-2 ">Consent obtained</td>
            <td className="border-2 ">Affiliated companies</td>
          </tr>
        </tbody>
      </table>
      <p>
      The company will store your personal data for as long as necessary to fulfill the purposes stated above, to establish legal claims, to comply with legal obligations, and for the legitimate interests of the company or other parties as specified in the company's personal data retention policy. The company will delete or destroy personal data, or make it anonymous when it is no longer necessary or when the stated period has ended.
      </p>
      <div className="mt-3 text-m font-bold">
        4. Measures to ensure the security of personal data
      </div>
      <p>
        The company places importance on the security of your personal data and has established appropriate technical and management measures to ensure that your personal data is not accessed, modified, altered, deleted or destroyed by unauthorized persons. The company also reviews these measures periodically as necessary or when there are changes in technology to ensure the effectiveness of the security of personal data.
      </p>
      <div className="mt-3 text-m font-bold">
        5. Exercising Legal Rights as the Data Subject
      </div>
      <p>
        You have the right to exercise any of the rights provided under the criteria specified in the Personal Data Protection Act B.E. 2562, laws, regulations, and related announcements, including:
      </p>
      <p>
        5.1 Right to withdraw consent: You have the right to withdraw your consent to the processing of your personal data by the company at any time during the period that your personal data is with the company, unless there are legal restrictions or there is a contract between you and the company that benefits you.
      </p>
      <p>
        5.2 Right to access personal data and request disclosure of the source: You have the right to request access to your personal data that the company collects, uses and discloses, and have the right to request the company to disclose the source of the data.
      </p>
      <p>
        5.3 Right to rectify personal data: You have the right to request the company to rectify any inaccurate or incomplete personal data.
      </p>
      <p>
        5.4 Right to erase personal data: You have the right to request the company to erase your personal data if it is no longer necessary for the purposes for which the data was collected by the company.
      </p>
      <p>
        5.5 Right to suspend the processing of personal data: You have the right to request the company to suspend the processing of your personal data in certain circumstances.
      </p>
      <p>
        5.6 Right to data portability: You have the right to request the automatic transfer of your personal data to another data controller, unless it is technically impossible. However, the company does not currently have a system that supports this right.
      </p>
      <p>
        5.7 Right to object to the processing of personal data: You have the right to object to the processing of your personal data in certain circumstances.
      </p>
      <p>
        Please note that you may not be able to exercise your legal rights, or exercising your rights may incur costs, if your request falls under any of the following conditions:
      </p>
      <li>
        It conflicts or violates the rights provided under the Personal Data Protection Act B.E. 2562, laws, or related announcements.
      </li>
      <li>It conflicts or violates the provisions of the law or court orders.</li>
      <li>It affects the rights and freedoms of others.</li>
      <li>It is exercised in bad faith or is unreasonable.</li>
      <li>
        It affects the investigation, inquiry, adjudication, or judgment process of the court.
      </li>
      <p>
        You can exercise your rights through the company's customer service center or by email at
        yaks.club@gmail.com
      </p>
      <p>
        Please note that exercising any of the aforementioned rights may result in you losing some rights to receive benefits or privileges from using the company's services and products.
      </p>
      <div className="mt-3 text-m font-bold">
        6. Notification Responsibilities for Changes
      </div>
      <p>
        The company places importance on respecting your privacy and ensuring that your personal data is accurate and up-to-date. If there are any changes to your personal data, please notify the company immediately.
      </p>
      <div className="mt-3 text-m font-bold">7. Contact Details</div>
      <p>
        Drive Digital Co., Ltd.
        333/1064 Bangkok-Nonthaburi Road, Bang Khen Subdistrict,
        Mueang Nonthaburi District, Nonthaburi Province, 11000
        This policy was last updated on September 15, 2022.
      </p>
    </div>
  );
};

export default Policy;
