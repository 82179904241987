import { Fragment } from "react";
import { Link, NavLink, Outlet, useLocation } from "react-router-dom";
import yaksLogo from "../../assets/navigation/Frame.png";
import React, { useState } from "react";
import { Transition } from "@headlessui/react";
import Facebook from "../../assets/navigation/Facebook - Negative.png";
import Discord from "../../assets/navigation/discord.png";
import Instagram from "../../assets/navigation/instagram.png";
import AppStore from "../../assets/navigation/app_store.png";
import PlayStore from "../../assets/navigation/google-play-badge 1.png";
import { Link as ScrollTo } from "react-scroll";

const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const path = location.pathname;
  const isEN = path == "/en";
  return (
    <Fragment>
      <div className="sticky top-0 z-50">
        <nav className="bg-white">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex items-center justify-between h-16 ">
              <div className="flex items-center justify-between w-screen">
                <div className="flex-shrink-0">
                  <Link to="/">
                    <img src={yaksLogo} className="h-8 w-32" />
                  </Link>
                </div>
                <div className="hidden md:block">
                  <div className="ml-10 flex items-baseline space-x-4">
                    <ScrollTo
                      className=" text-black px-3 py-2 rounded-md text-sm font-medium cursor-pointer"
                      to="amazingthailandnft"
                      spy={true}
                      smooth={true}
                    >
                      Amazing Thailand NFT
                    </ScrollTo>
                    <ScrollTo
                      className="text-black px-3 py-2 rounded-md text-sm font-medium cursor-pointer"
                      to="privilege"
                      spy={true}
                      smooth={true}
                    >
                      {isEN ? "Privilege" : "สิทธิประโยชน์"}
                    </ScrollTo>
                    <ScrollTo
                      className="text-black px-3 py-2 rounded-md text-sm font-medium cursor-pointer"
                      to="rankandlevel"
                      spy={true}
                      smooth={true}
                    >
                      {isEN ? "Rank and Level" : "ระดับและความหายาก"}
                    </ScrollTo>

                    {isEN ? (
                      <div className="flex bg-slate-200 rounded-lg">
                        <div className="px-1 bg-white  m-0.5 rounded-lg">
                          EN
                        </div>
                        <div className="px-1 text-slate-400 m-0.5 rounded-lg">
                          <NavLink to="/">TH</NavLink>
                        </div>
                      </div>
                    ) : (
                      <div className="flex bg-slate-200 rounded-lg">
                        <div className="px-1 text-slate-400 m-0.5 rounded-lg">
                          <NavLink to="/en">EN</NavLink>
                        </div>
                        <div className="px-1 bg-white m-0.5 rounded-lg">TH</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="-mr-2 flex md:hidden">
                <button
                  onClick={() => setIsOpen(!isOpen)}
                  type="button"
                  className="bg-white inline-flex items-center justify-center p-2 rounded-md text-black"
                  aria-controls="mobile-menu"
                  aria-expanded="false"
                >
                  <span className="sr-only">Open main menu</span>
                  {!isOpen ? (
                    <svg
                      className="block h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4 6h16M4 12h16M4 18h16"
                      />
                    </svg>
                  ) : (
                    <svg
                      className="block h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  )}
                </button>
              </div>
            </div>
          </div>

          <Transition
            show={isOpen}
            enter="transition ease-out duration-100 transform"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="transition ease-in duration-75 transform"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            {(ref) => (
              <div className="md:hidden" id="mobile-menu">
                <div ref={ref} className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                  <ScrollTo
                    className=" teblack block px-3 py-2 rounded-md text-base font-medium"
                    to="amazingthailandnft"
                    spy={true}
                    smooth={true}
                  >
                    Amazing Thailand NFT
                  </ScrollTo>

                  <ScrollTo
                    className="text-black block px-3 py-2 rounded-md text-base font-medium"
                    to="privilege"
                    spy={true}
                    smooth={true}
                  >
                    {isEN ? "Privilege" : "สิทธิประโยชน์"}
                  </ScrollTo>

                  <ScrollTo
                    className="text-black block px-3 py-2 rounded-md text-base font-medium"
                    to="yaksnft"
                    spy={true}
                    smooth={true}
                  >
                    YAKS NFT
                  </ScrollTo>

                  <ScrollTo
                    className="text-black block px-3 py-2 rounded-md text-base font-medium"
                    to="rankandlevel"
                    spy={true}
                    smooth={true}
                  >
                    Rank and Level
                  </ScrollTo>
                  <div className="flex flex-row px-3 py-2">
                    {isEN ? (
                      <div className="flex bg-slate-200 rounded-lg ">
                        <div className="px-1 bg-white  m-0.5 rounded-lg">
                          EN
                        </div>
                        <div className="px-1 text-slate-400 m-0.5 rounded-lg">
                          <NavLink to="/">TH</NavLink>
                        </div>
                      </div>
                    ) : (
                      <div className="flex bg-slate-200 rounded-lg ">
                        <div className="px-1 text-slate-400 m-0.5 rounded-lg">
                          <NavLink to="/en">EN</NavLink>
                        </div>
                        <div className="px-1 bg-white m-0.5 rounded-lg">TH</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </Transition>
        </nav>
      </div>

      <Outlet />
      <div className="flex flex-col items-center w-full bg-footer_bg  mt-24 md:flex-row md:text-xs md:justify-between md:items-end bg-no-repeat bg-right md:p-5">
        <div className="text-center md:flex md:flex-row md:w-[600px]  md:justify-start xl:text-lg">
          <div className="flex flex-row justify-center my-5 md:my-0 ">
            <div>
              <NavLink to="/privacy">Privacy</NavLink>
            </div>
            <div>&emsp;|&emsp;</div>
            <div>
              <NavLink to="/term">Terms</NavLink>
            </div>
          </div>
          <div className="md:ml-3">
            ©2023 Drive Digital Co., Ltd. All right reserved.
          </div>
        </div>
        <div className="md:flex md:flex-col md:justify-start">
          <div className="flex flex-row py-5 justify-center md:justify-end md:py-0">
            <div>
              <a href="https://www.facebook.com/YAKSnft" target="_blank">
                <img src={Facebook} className="px-2" />
              </a>
            </div>
            <div>
              <a href="https://discord.gg/GBBQVwqy" target="_blank">
                <img src={Discord} className="px-2" />
              </a>
            </div>
            <div>
              <a
                href="https://www.instagram.com/yaksxamazingthailandnft/"
                target="_blank"
              >
                <img src={Instagram} className="px-2" />
              </a>
            </div>
          </div>

          <div className="flex flex-row md:justify-end mt-3">
            <a
              href="https://apps.apple.com/app/yaks-x-amazing-thailand-nfts/id6443812299"
              target="_blank"
              className="px-1 md:w-1/3 "
            >
              <img src={AppStore} />
            </a>
            <a
              href="https://storage.googleapis.com/yaks-app-image/app-release.apk"
              target="_blank"
              className="px-1 md:w-1/3"
            >
              <img src={PlayStore} />
            </a>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Navigation;
