const AmazingNftCard = ({ imagePath, header, description }) => {
  return (
    <div className="p-4 mb-5 border bg-white inline-block rounded-lg drop-shadow-xl text-center md:mx-5 flex flex-col items-center">
      <img className="rounded-xl" src={imagePath} />
      <div className="text-xl font-bold mt-5">{header}</div>
      <div className="text-lg font-light mt-3 h-[70px]">
        {description.map((item) => (
          <div key={item}>- {item}</div>
        ))}
      </div>
    </div>
  );
};

export default AmazingNftCard;
