import kioskIcon from "../../assets/collect_amazing_nft/kiosk.png";
import One from "../../assets/svg/1.svg";
import Two from "../../assets/svg/2.svg";
import Three from "../../assets/svg/3.svg";
import CollectNftElement from "./collect_nft_element";
import Arrow from "../../assets/svg/arrow.svg";
import ScanQR from "../../assets/collect_amazing_nft/scan_qr.png";
import Discount from "../../assets/collect_amazing_nft/discount.png";
import { useLocation } from "react-router-dom";
const CollectAmazingNft = () => {
  const location = useLocation();
  const path = location.pathname;
  const isEN = path == "/en";
  return (
    <div className="flex flex-col text-center items-center md:mx-3 xl:mx-32 mt-20">
      <div className="text-xl md:text-2xl font-notoSans font-semibold text-transparent bg-clip-text bg-gradient-to-r from-red-gradient to-purple-gradient z-20">
        [Amazing Thailand NFTs Season 3]
      </div>
      <div className="text-2xl mx-8 md:text-xl xl:text-3xl font-semibold mt-5">
        {isEN ? "How to collect Amazing Thailand NFTs Season 3" : "วิธีการสะสม Amazing Thailand NFTs Season 3"}
      </div>
      <div className="text-lg mt-3 leading-8 xl:text-xl font-light mx-5 xl:px-56 text-center">
        {isEN ? "Get a free NFT throughout August 2023 only!" : "รับ ​NFT ฟรี! ตลอดเดือนสิงหาคม 2566 เท่านั้น!"}
      </div>
      <div className="mt-10 flex flex-col md:flex-row justify-center xl:items-center">
        <CollectNftElement
          imagePath={kioskIcon}
          iconPath={One}
          header="Download the app"
          description={
            isEN
              ? "Download the YAKS app and get your NFT immediately upon registration."
              : "ดาวน์โหลดแอป YAKS และรับ NFT ทันที เมื่อลงทะเบียนเข้าใช้งาน"
          }
        />
        <img src={Arrow} className="h-5 rotate-90 md:rotate-0 my-10 xl:h-7" />
        <CollectNftElement
          imagePath={ScanQR}
          iconPath={Two}
          header="Travel"
          description={
            isEN
              ? 'Travel to a designated location on the "Places" menu.'
              : "ออกเดินทางไปยังสถานที่ที่ปักหมุดในแผนที่ ของเมนู ‘สถานที่’"
          }
        />
        <img src={Arrow} className="h-5 rotate-90 md:rotate-0 my-10 xl:h-7" />
        <CollectNftElement
          imagePath={Discount}
          iconPath={Three}
          header="Check-in at Landmark"
          description={
            isEN
              ? "Check in within a 2-kilometer radius of the landmark to receive your free Amazing Thailand NFTs Season 3."
              : "เช็คอินในรัศมี 2 กิโลเมตรจากจุดแลนด์มาร์ก เพื่อรับ Amazing Thailand NFTs Season 3 ฟรี"
          }
        />
        <img src={Arrow} className="h-5 rotate-90 md:rotate-0 my-10 xl:h-7" />
        <CollectNftElement
          imagePath={Discount}
          iconPath={Three}
          header={isEN ? "Enjoy Privilege!" : "Enjoy Privilege!"}
          description={
            isEN
              ? 'Enjoy a variety of benefits. \nNote: To complete your NFT art collection, you can press "Mint" to connect with Bitkub Wallet through the YAKS app.'
              : "เพลินเพลินกับสิทธิประโยชน์มากมาย"
          }
        />
      </div>
      <div className="mt-8">
          {isEN ? 'Note: To complete your NFT art collection, you can press "Mint" to connect with Bitkub Wallet through the YAKS app.'
            : "หมายเหตุ: เพื่อให้การสะสมชิ้นงานศิลปะ NFT สมบูรณ์มากยิ่งขึ้น สามารถกด Mint เพื่อเชื่อมกับ Bitkub Wallet ผ่านแอปพลิเคชัน YAKS"}
        </div>
    </div>
  );
};

export default CollectAmazingNft;
