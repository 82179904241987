const LocationCard = ({ imagePath, thaiName, engName, linkTo }) => {
  return (
    <div class="relative mt-5 mx-3 md:mt-0 md:w-1/3 xl:w-[400px] md:mx-2.5 rounded-3xl overflow-hidden">
      <a href={linkTo} target="_blank">
        <img class="rounded-3xl" src={imagePath} />
      </a>
      <div class="absolute bottom-0 left-0 right-0 px-2 py-2 bg-black-with-opacity-70 rounded-b-3xl">
        <h3 class="text-xl text-white font-bold text-center">{thaiName}</h3>
      </div>
    </div>
  );
};

export default LocationCard;
