import LocationCard from "./location_card";
import Esan1 from "../../assets/location_nft/ss3/เขายายเที่ยง นครราชสีมา/อีสาน1.png";
import Esan2 from "../../assets/location_nft/ss3/เขายายเที่ยง นครราชสีมา/อีสาน2.png";
import Esan3 from "../../assets/location_nft/ss3/เขายายเที่ยง นครราชสีมา/อีสาน3.png";
import Esan4 from "../../assets/location_nft/ss3/ถ้ำนาคา บึงกาฬ/อีสาน4.png";
import Esan5 from "../../assets/location_nft/ss3/ถ้ำนาคา บึงกาฬ/อีสาน5.png";
import Esan6 from "../../assets/location_nft/ss3/ถ้ำนาคา บึงกาฬ/อีสาน6.png";
import Esan7 from "../../assets/location_nft/ss3/ประเพณีแห่เทียนพรรษา อุบลราชธานี/อีสาน7.png";
import Esan8 from "../../assets/location_nft/ss3/ประเพณีแห่เทียนพรรษา อุบลราชธานี/อีสาน8.png";
import Esan9 from "../../assets/location_nft/ss3/ประเพณีแห่เทียนพรรษา อุบลราชธานี/อีสาน9.png";
import Esan10 from "../../assets/location_nft/ss3/พญาศรีสัตตนาคราช นครพนม/อีสาน10.png";
import Esan11 from "../../assets/location_nft/ss3/พญาศรีสัตตนาคราช นครพนม/อีสาน11.png";
import Esan12 from "../../assets/location_nft/ss3/พญาศรีสัตตนาคราช นครพนม/อีสาน12.png";
import Esan13 from "../../assets/location_nft/ss3/หมู่บ้านช้างบ้านตากลาง สุรินทร์/อีสาน13.png";
import Esan14 from "../../assets/location_nft/ss3/หมู่บ้านช้างบ้านตากลาง สุรินทร์/อีสาน14.png";
import Esan15 from "../../assets/location_nft/ss3/หมู่บ้านช้างบ้านตากลาง สุรินทร์/อีสาน15.png";
import Esan16 from "../../assets/location_nft/ss3/อุทยานประวัติศาสตร์พนมรุ้ง บุรีรัมย์/อีสาน16.png";
import Esan17 from "../../assets/location_nft/ss3/อุทยานประวัติศาสตร์พนมรุ้ง บุรีรัมย์/อีสาน17.png";
import Esan18 from "../../assets/location_nft/ss3/อุทยานประวัติศาสตร์พนมรุ้ง บุรีรัมย์/อีสาน18.png";
import Esan19 from "../../assets/location_nft/ss3/อุทยานแห่งชาติป่าหินงาม ชัยภูมิ/อีสาน19.png";
import Esan20 from "../../assets/location_nft/ss3/อุทยานแห่งชาติป่าหินงาม ชัยภูมิ/อีสาน20.png";
import Esan21 from "../../assets/location_nft/ss3/อุทยานแห่งชาติป่าหินงาม ชัยภูมิ/อีสาน21.png";
import North1 from "../../assets/location_nft/ss3/ถนนคนเดินเชียงคาน เลย/เหนือ1.png";
import North2 from "../../assets/location_nft/ss3/ถนนคนเดินเชียงคาน เลย/เหนือ2.png";
import North3 from "../../assets/location_nft/ss3/ถนนคนเดินเชียงคาน เลย/เหนือ3.png";


import { useLocation } from "react-router-dom";
const LocationsNft = () => {
  const location = useLocation();
  const path = location.pathname;
  const isEN = path == "/en";
  return (
    <div className="flex flex-col items-center mt-20 " id="amazingthailandnft">
      
      <div className="text-2xl text-center mx-5 mt-5 md:text-3xl font-notoSans font-semibold md:mt-5 z-20">
        {isEN ? "Special NFT art collection of 40 landmarks across 5 regions." : "NFT คอลเลคชั่นสถานที่ท่องเที่ยว 40 แลนด์มาร์ก  5 ภูมิภาค"}
      </div>
      <div className="text-lg mt-3 leading-8 xl:text-xl font-light mx-5 xl:px-56 text-center">
        {isEN ? "Check in to receive a free NFT within a 2-kilometer radius of the landmarks." : "Check-in เพื่อรับ NFT ฟรี ในรัศมี 2 กิโลเมตรจาก Landmark"}
      </div>
      <div className="flex flex-col md:flex-row md:justify-center md:mt-5 md:mb-9 xl:mt-10">
        <LocationCard
          linkTo="https://www.google.co.th/maps/place/%E0%B9%80%E0%B8%82%E0%B8%B2%E0%B8%A2%E0%B8%B2%E0%B8%A2%E0%B9%80%E0%B8%97%E0%B8%B5%E0%B9%88%E0%B8%A2%E0%B8%87/@14.8461783,101.5579081,15z/data=!3m1!4b1!4m6!3m5!1s0x311e9b77b1cf6523:0x43a1e808f3ba16dd!8m2!3d14.8461788!4d101.5682078!16s%2Fg%2F1223wy6z?hl=th&entry=ttu"
          imagePath={Esan1}
          thaiName={isEN ? "Khao Yai Thiang": "เขายายเที่ยง นครราชสีมา"}
          engName={isEN ? "Khao Yai Thiang" : "เขายายเที่ยง นครราชสีมา"}
        />
        <LocationCard
          linkTo="https://www.google.co.th/maps/place/%E0%B9%80%E0%B8%82%E0%B8%B2%E0%B8%A2%E0%B8%B2%E0%B8%A2%E0%B9%80%E0%B8%97%E0%B8%B5%E0%B9%88%E0%B8%A2%E0%B8%87/@14.8461783,101.5579081,15z/data=!3m1!4b1!4m6!3m5!1s0x311e9b77b1cf6523:0x43a1e808f3ba16dd!8m2!3d14.8461788!4d101.5682078!16s%2Fg%2F1223wy6z?hl=th&entry=ttu"
          imagePath={Esan2}
          thaiName={isEN ? "Khao Yai Thiang": "เขายายเที่ยง นครราชสีมา"}
          engName={isEN ? "Khao Yai Thiang" : "เขายายเที่ยง นครราชสีมา"}
        />
        <LocationCard
          linkTo="https://www.google.co.th/maps/place/%E0%B9%80%E0%B8%82%E0%B8%B2%E0%B8%A2%E0%B8%B2%E0%B8%A2%E0%B9%80%E0%B8%97%E0%B8%B5%E0%B9%88%E0%B8%A2%E0%B8%87/@14.8461783,101.5579081,15z/data=!3m1!4b1!4m6!3m5!1s0x311e9b77b1cf6523:0x43a1e808f3ba16dd!8m2!3d14.8461788!4d101.5682078!16s%2Fg%2F1223wy6z?hl=th&entry=ttu"
          imagePath={Esan3}
          thaiName={isEN ? "Asiatique The Riverfront" : "เขายายเที่ยง นครราชสีมา"}
          engName={isEN ? "Khao Yai Thiang": "เขายายเที่ยง นครราชสีมา"}
        />
        <LocationCard
          linkTo="https://www.google.co.th/maps/place/%E0%B8%96%E0%B9%89%E0%B8%B3%E0%B8%99%E0%B8%B2%E0%B8%84%E0%B8%B2+%E0%B8%9A%E0%B8%B6%E0%B8%87%E0%B8%81%E0%B8%B2%E0%B8%AC/@17.9523125,104.1019876,17z/data=!3m1!4b1!4m6!3m5!1s0x313b0d5feb16ab1f:0xb1cc5fcccb5685e2!8m2!3d17.9523125!4d104.1045625!16s%2Fg%2F11fw80lnwz?hl=th&entry=ttu"
          imagePath={Esan4}
          thaiName={isEN ? "Naka Cave" : "ถ้ำนาคา บึงกาฬ"}
          engName={isEN ? "Naka Cave": "ถ้ำนาคา บึงกาฬ"}
        />
      </div>

      <div className="flex flex-col md:flex-row md:justify-center ">
        <LocationCard
          linkTo="https://www.google.co.th/maps/place/%E0%B8%96%E0%B9%89%E0%B8%B3%E0%B8%99%E0%B8%B2%E0%B8%84%E0%B8%B2+%E0%B8%9A%E0%B8%B6%E0%B8%87%E0%B8%81%E0%B8%B2%E0%B8%AC/@17.9523125,104.1019876,17z/data=!3m1!4b1!4m6!3m5!1s0x313b0d5feb16ab1f:0xb1cc5fcccb5685e2!8m2!3d17.9523125!4d104.1045625!16s%2Fg%2F11fw80lnwz?hl=th&entry=ttu"
          imagePath={Esan5}
          thaiName={isEN ? "Naka Cave" : "ถ้ำนาคา บึงกาฬ"}
          engName={isEN ? "Naka Cave": "ถ้ำนาคา บึงกาฬ"}
        />
        <LocationCard
          linkTo="https://www.google.co.th/maps/place/%E0%B8%96%E0%B9%89%E0%B8%B3%E0%B8%99%E0%B8%B2%E0%B8%84%E0%B8%B2+%E0%B8%9A%E0%B8%B6%E0%B8%87%E0%B8%81%E0%B8%B2%E0%B8%AC/@17.9523125,104.1019876,17z/data=!3m1!4b1!4m6!3m5!1s0x313b0d5feb16ab1f:0xb1cc5fcccb5685e2!8m2!3d17.9523125!4d104.1045625!16s%2Fg%2F11fw80lnwz?hl=th&entry=ttu"
          imagePath={Esan6}
          thaiName={isEN ? "Naka Cave" : "ถ้ำนาคา บึงกาฬ"}
          engName={isEN ? "Naka Cave" : "ถ้ำนาคา บึงกาฬ"}
        />
         <LocationCard
          linkTo="https://www.google.co.th/maps/place/%E0%B8%AD%E0%B8%B8%E0%B8%9A%E0%B8%A5%E0%B8%A3%E0%B8%B2%E0%B8%8A%E0%B8%98%E0%B8%B2%E0%B8%99%E0%B8%B5/@15.1503982,103.7555014,9z/data=!3m1!4b1!4m6!3m5!1s0x31142eb485e0fccd:0x3786785e8dd045c7!8m2!3d15.0727086!4d105.2194808!16zL20vMDFrNDZ5?hl=th&entry=ttu"
          imagePath={Esan7}
          thaiName={isEN ? "Candle Festival, Ubon Ratchatani" : "ประเพณีแห่เทียนพรรษา อุบลราชธานี"}
          engName={isEN ? "Candle Festival, Ubon Ratchatani": "ประเพณีแห่เทียนพรรษา อุบลราชธานี"}
        />
        <LocationCard
          linkTo="https://www.google.co.th/maps/place/%E0%B8%AD%E0%B8%B8%E0%B8%9A%E0%B8%A5%E0%B8%A3%E0%B8%B2%E0%B8%8A%E0%B8%98%E0%B8%B2%E0%B8%99%E0%B8%B5/@15.1503982,103.7555014,9z/data=!3m1!4b1!4m6!3m5!1s0x31142eb485e0fccd:0x3786785e8dd045c7!8m2!3d15.0727086!4d105.2194808!16zL20vMDFrNDZ5?hl=th&entry=ttu"
          imagePath={Esan8}
          thaiName={isEN ? "Candle Festival, Ubon Ratchatani" : "ประเพณีแห่เทียนพรรษา อุบลราชธานี"}
          engName={isEN ? "Candle Festival, Ubon Ratchatani" : "ประเพณีแห่เทียนพรรษา อุบลราชธานี"}
        />
      </div>

      <div className="flex flex-col md:flex-row md:justify-center md:mt-5 md:mb-9 xl:mt-10">
        <LocationCard
          linkTo="https://www.google.co.th/maps/place/%E0%B8%AD%E0%B8%B8%E0%B8%9A%E0%B8%A5%E0%B8%A3%E0%B8%B2%E0%B8%8A%E0%B8%98%E0%B8%B2%E0%B8%99%E0%B8%B5/@15.1503982,103.7555014,9z/data=!3m1!4b1!4m6!3m5!1s0x31142eb485e0fccd:0x3786785e8dd045c7!8m2!3d15.0727086!4d105.2194808!16zL20vMDFrNDZ5?hl=th&entry=ttu"
          imagePath={Esan9}
          thaiName={isEN ? "Candle Festival, Ubon Ratchatani": "ประเพณีแห่เทียนพรรษา อุบลราชธานี"}
          engName={isEN ? "Candle Festival, Ubon Ratchatani" : "ประเพณีแห่เทียนพรรษา อุบลราชธานี"}
        />
        <LocationCard
          linkTo="https://www.google.co.th/maps/place/%E0%B8%A5%E0%B8%B2%E0%B8%99%E0%B8%9E%E0%B8%8D%E0%B8%B2%E0%B8%A8%E0%B8%A3%E0%B8%B5%E0%B8%AA%E0%B8%B1%E0%B8%95%E0%B8%95%E0%B8%99%E0%B8%B2%E0%B8%84%E0%B8%A3%E0%B8%B2%E0%B8%8A+%E0%B8%99%E0%B8%84%E0%B8%A3%E0%B8%9E%E0%B8%99%E0%B8%A1/@17.3993855,104.7879685,17z/data=!3m1!4b1!4m6!3m5!1s0x313c092c39f8c75f:0x338da844c2a4e9d7!8m2!3d17.3993855!4d104.7905434!16s%2Fg%2F11c55rmqsr?hl=th&entry=ttu"
          imagePath={Esan10}
          thaiName={isEN ? "Phaya Si Satta Nakharat, Nakhon Phanom": "พญาศรีสัตตนาคราช นครพนม"}
          engName={isEN ? "Phaya Si Satta Nakharat, Nakhon Phanom" : "พญาศรีสัตตนาคราช นครพนม"}
        />
        <LocationCard
          linkTo="https://www.google.co.th/maps/place/%E0%B8%A5%E0%B8%B2%E0%B8%99%E0%B8%9E%E0%B8%8D%E0%B8%B2%E0%B8%A8%E0%B8%A3%E0%B8%B5%E0%B8%AA%E0%B8%B1%E0%B8%95%E0%B8%95%E0%B8%99%E0%B8%B2%E0%B8%84%E0%B8%A3%E0%B8%B2%E0%B8%8A+%E0%B8%99%E0%B8%84%E0%B8%A3%E0%B8%9E%E0%B8%99%E0%B8%A1/@17.3993855,104.7879685,17z/data=!3m1!4b1!4m6!3m5!1s0x313c092c39f8c75f:0x338da844c2a4e9d7!8m2!3d17.3993855!4d104.7905434!16s%2Fg%2F11c55rmqsr?hl=th&entry=ttu"
          imagePath={Esan11}
          thaiName={isEN ? "Phaya Si Satta Nakharat, Nakhon Phanom" : "พญาศรีสัตตนาคราช นครพนม"}
          engName={isEN ? "Phaya Si Satta Nakharat, Nakhon Phanom": "พญาศรีสัตตนาคราช นครพนม"}
        />
        <LocationCard
          linkTo="https://www.google.co.th/maps/place/%E0%B8%A5%E0%B8%B2%E0%B8%99%E0%B8%9E%E0%B8%8D%E0%B8%B2%E0%B8%A8%E0%B8%A3%E0%B8%B5%E0%B8%AA%E0%B8%B1%E0%B8%95%E0%B8%95%E0%B8%99%E0%B8%B2%E0%B8%84%E0%B8%A3%E0%B8%B2%E0%B8%8A+%E0%B8%99%E0%B8%84%E0%B8%A3%E0%B8%9E%E0%B8%99%E0%B8%A1/@17.3993855,104.7879685,17z/data=!3m1!4b1!4m6!3m5!1s0x313c092c39f8c75f:0x338da844c2a4e9d7!8m2!3d17.3993855!4d104.7905434!16s%2Fg%2F11c55rmqsr?hl=th&entry=ttu"
          imagePath={Esan12}
          thaiName={isEN ? "Phaya Si Satta Nakharat, Nakhon Phanom" : "พญาศรีสัตตนาคราช นครพนม"}
          engName={isEN ? "Phaya Si Satta Nakharat, Nakhon Phanom": "พญาศรีสัตตนาคราช นครพนม"}
        />
      </div>

      <div className="flex flex-col md:flex-row md:justify-center md:mt-5 md:mb-9 xl:mt-10">
        <LocationCard
          linkTo="https://www.google.co.th/maps/search/%E0%B8%AB%E0%B8%A1%E0%B8%B9%E0%B9%88%E0%B8%9A%E0%B9%89%E0%B8%B2%E0%B8%99%E0%B8%8A%E0%B9%89%E0%B8%B2%E0%B8%87%E0%B8%95%E0%B8%B2%E0%B8%81%E0%B8%A5%E0%B8%B2%E0%B8%87/@15.2575103,103.4944053,15z/data=!3m1!4b1?hl=th&entry=ttu"
          imagePath={Esan13}
          thaiName={isEN ? "Ban Ta Klang Elephant Village, Surin": "หมู่บ้านช้างบ้านตากลาง สุรินทร์"}
          engName={isEN ? "Ban Ta Klang Elephant Village, Surin" : "หมู่บ้านช้างบ้านตากลาง สุรินทร์"}
        />
        <LocationCard
          linkTo="https://www.google.co.th/maps/search/%E0%B8%AB%E0%B8%A1%E0%B8%B9%E0%B9%88%E0%B8%9A%E0%B9%89%E0%B8%B2%E0%B8%99%E0%B8%8A%E0%B9%89%E0%B8%B2%E0%B8%87%E0%B8%95%E0%B8%B2%E0%B8%81%E0%B8%A5%E0%B8%B2%E0%B8%87/@15.2575103,103.4944053,15z/data=!3m1!4b1?hl=th&entry=ttu"
          imagePath={Esan14}
          thaiName={isEN ? "Ban Ta Klang Elephant Village, Surin": "หมู่บ้านช้างบ้านตากลาง สุรินทร์"}
          engName={isEN ? "Ban Ta Klang Elephant Village, Surin" : "หมู่บ้านช้างบ้านตากลาง สุรินทร์"}
        />
        <LocationCard
          linkTo="https://www.google.co.th/maps/search/%E0%B8%AB%E0%B8%A1%E0%B8%B9%E0%B9%88%E0%B8%9A%E0%B9%89%E0%B8%B2%E0%B8%99%E0%B8%8A%E0%B9%89%E0%B8%B2%E0%B8%87%E0%B8%95%E0%B8%B2%E0%B8%81%E0%B8%A5%E0%B8%B2%E0%B8%87/@15.2575103,103.4944053,15z/data=!3m1!4b1?hl=th&entry=ttu"
          imagePath={Esan15}
          thaiName={isEN ? "Ban Ta Klang Elephant Village, Surin" : "หมู่บ้านช้างบ้านตากลาง สุรินทร์"}
          engName={isEN ? "Ban Ta Klang Elephant Village, Surin": "หมู่บ้านช้างบ้านตากลาง สุรินทร์"}
        />
        <LocationCard
          linkTo="https://www.google.co.th/maps/place/%E0%B8%AD%E0%B8%B8%E0%B8%97%E0%B8%A2%E0%B8%B2%E0%B8%99%E0%B8%9B%E0%B8%A3%E0%B8%B0%E0%B8%A7%E0%B8%B1%E0%B8%95%E0%B8%B4%E0%B8%A8%E0%B8%B2%E0%B8%AA%E0%B8%95%E0%B8%A3%E0%B9%8C%E0%B8%9E%E0%B8%99%E0%B8%A1%E0%B8%A3%E0%B8%B8%E0%B9%89%E0%B8%87/@14.5325065,102.9377453,17z/data=!3m1!4b1!4m6!3m5!1s0x311bd4d819ab46c1:0x9dfb475988ec4e0d!8m2!3d14.5319352!4d102.9403306!16zL20vMDJ0dHd4?hl=th&entry=ttu"
          imagePath={Esan16}
          thaiName={isEN ? "Phanom Rung Historical Park, Buriram" : "อุทยานประวัติศาสตร์พนมรุ้ง บุรีรัมย์"}
          engName={isEN ? "Phanom Rung Historical Park, Buriram": "อุทยานประวัติศาสตร์พนมรุ้ง บุรีรัมย์"}
        />
      </div>

      <div className="flex flex-col md:flex-row md:justify-center md:mt-5 md:mb-9 xl:mt-10">
        <LocationCard
          linkTo="https://www.google.co.th/maps/place/%E0%B8%AD%E0%B8%B8%E0%B8%97%E0%B8%A2%E0%B8%B2%E0%B8%99%E0%B8%9B%E0%B8%A3%E0%B8%B0%E0%B8%A7%E0%B8%B1%E0%B8%95%E0%B8%B4%E0%B8%A8%E0%B8%B2%E0%B8%AA%E0%B8%95%E0%B8%A3%E0%B9%8C%E0%B8%9E%E0%B8%99%E0%B8%A1%E0%B8%A3%E0%B8%B8%E0%B9%89%E0%B8%87/@14.5325065,102.9377453,17z/data=!3m1!4b1!4m6!3m5!1s0x311bd4d819ab46c1:0x9dfb475988ec4e0d!8m2!3d14.5319352!4d102.9403306!16zL20vMDJ0dHd4?hl=th&entry=ttu"
          imagePath={Esan17}
          thaiName={isEN ? "Phanom Rung Historical Park, Buriram": "อุทยานประวัติศาสตร์พนมรุ้ง บุรีรัมย์"}
          engName={isEN ? "Phanom Rung Historical Park, Buriram" : "อุทยานประวัติศาสตร์พนมรุ้ง บุรีรัมย์"}
        />
        <LocationCard
          linkTo="https://www.google.co.th/maps/place/%E0%B8%AD%E0%B8%B8%E0%B8%97%E0%B8%A2%E0%B8%B2%E0%B8%99%E0%B8%9B%E0%B8%A3%E0%B8%B0%E0%B8%A7%E0%B8%B1%E0%B8%95%E0%B8%B4%E0%B8%A8%E0%B8%B2%E0%B8%AA%E0%B8%95%E0%B8%A3%E0%B9%8C%E0%B8%9E%E0%B8%99%E0%B8%A1%E0%B8%A3%E0%B8%B8%E0%B9%89%E0%B8%87/@14.5325065,102.9377453,17z/data=!3m1!4b1!4m6!3m5!1s0x311bd4d819ab46c1:0x9dfb475988ec4e0d!8m2!3d14.5319352!4d102.9403306!16zL20vMDJ0dHd4?hl=th&entry=ttu"
          imagePath={Esan18}
          thaiName={isEN ? "Phanom Rung Historical Park, Buriram": "อุทยานประวัติศาสตร์พนมรุ้ง บุรีรัมย์"}
          engName={isEN ? "Phanom Rung Historical Park, Buriram" : "อุทยานประวัติศาสตร์พนมรุ้ง บุรีรัมย์"}
        />
        <LocationCard
          linkTo="https://www.google.co.th/maps/place/%E0%B8%AD%E0%B8%B8%E0%B8%97%E0%B8%A2%E0%B8%B2%E0%B8%99%E0%B9%81%E0%B8%AB%E0%B9%88%E0%B8%87%E0%B8%8A%E0%B8%B2%E0%B8%95%E0%B8%B4%E0%B8%9B%E0%B9%88%E0%B8%B2%E0%B8%AB%E0%B8%B4%E0%B8%99%E0%B8%87%E0%B8%B2%E0%B8%A1+%E0%B8%8A%E0%B8%B1%E0%B8%A2%E0%B8%A0%E0%B8%B9%E0%B8%A1%E0%B8%B4/@15.631622,101.3913172,17z/data=!3m1!4b1!4m6!3m5!1s0x311f0114cfe90653:0x7882380fa6647f17!8m2!3d15.631622!4d101.3938921!16zL20vMDFwbmJy?hl=th&entry=ttu"
          imagePath={Esan19}
          thaiName={isEN ? "Pa Hin Ngam National Park, Chaiyaphum" : "อุทยานแห่งชาติป่าหินงาม ชัยภูมิ"}
          engName={isEN ? "Pa Hin Ngam National Park, Chaiyaphum": "อุทยานแห่งชาติป่าหินงาม ชัยภูมิ"}
        />
        <LocationCard
          linkTo="https://www.google.co.th/maps/place/%E0%B8%AD%E0%B8%B8%E0%B8%97%E0%B8%A2%E0%B8%B2%E0%B8%99%E0%B9%81%E0%B8%AB%E0%B9%88%E0%B8%87%E0%B8%8A%E0%B8%B2%E0%B8%95%E0%B8%B4%E0%B8%9B%E0%B9%88%E0%B8%B2%E0%B8%AB%E0%B8%B4%E0%B8%99%E0%B8%87%E0%B8%B2%E0%B8%A1+%E0%B8%8A%E0%B8%B1%E0%B8%A2%E0%B8%A0%E0%B8%B9%E0%B8%A1%E0%B8%B4/@15.631622,101.3913172,17z/data=!3m1!4b1!4m6!3m5!1s0x311f0114cfe90653:0x7882380fa6647f17!8m2!3d15.631622!4d101.3938921!16zL20vMDFwbmJy?hl=th&entry=ttu"
          imagePath={Esan20}
          thaiName={isEN ? "Pa Hin Ngam National Park, Chaiyaphum" : "อุทยานแห่งชาติป่าหินงาม ชัยภูมิ"}
          engName={isEN ? "Pa Hin Ngam National Park, Chaiyaphum": "อุทยานแห่งชาติป่าหินงาม ชัยภูมิ"}
        />
      </div>

      <div className="flex flex-col md:flex-row md:justify-center md:mt-5 md:mb-9 xl:mt-10">
        <LocationCard
          linkTo="https://www.google.co.th/maps/place/%E0%B8%AD%E0%B8%B8%E0%B8%97%E0%B8%A2%E0%B8%B2%E0%B8%99%E0%B9%81%E0%B8%AB%E0%B9%88%E0%B8%87%E0%B8%8A%E0%B8%B2%E0%B8%95%E0%B8%B4%E0%B8%9B%E0%B9%88%E0%B8%B2%E0%B8%AB%E0%B8%B4%E0%B8%99%E0%B8%87%E0%B8%B2%E0%B8%A1+%E0%B8%8A%E0%B8%B1%E0%B8%A2%E0%B8%A0%E0%B8%B9%E0%B8%A1%E0%B8%B4/@15.631622,101.3913172,17z/data=!3m1!4b1!4m6!3m5!1s0x311f0114cfe90653:0x7882380fa6647f17!8m2!3d15.631622!4d101.3938921!16zL20vMDFwbmJy?hl=th&entry=ttu"
          imagePath={Esan21}
          thaiName={isEN ? "Pa Hin Ngam National Park, Chaiyaphum": "อุทยานแห่งชาติป่าหินงาม ชัยภูมิ"}
          engName={isEN ? "Pa Hin Ngam National Park, Chaiyaphum" : "อุทยานแห่งชาติป่าหินงาม ชัยภูมิ"}
        />
        <LocationCard
          linkTo="https://www.google.co.th/maps/search/%E0%B8%96%E0%B8%99%E0%B8%99%E0%B8%84%E0%B8%99%E0%B9%80%E0%B8%94%E0%B8%B4%E0%B8%99%E0%B9%80%E0%B8%8A%E0%B8%B5%E0%B8%A2%E0%B8%87%E0%B8%84%E0%B8%B2%E0%B8%99/@17.8982801,101.6614589,17z/data=!3m1!4b1?hl=th&entry=ttu"
          imagePath={North1}
          thaiName={isEN ? "Chiang Khan Walking Street, Loei": "ถนนคนเดินเชียงคาน เลย"}
          engName={isEN ? "Chiang Khan Walking Street, Loei" : "ถนนคนเดินเชียงคาน เลย"}
        />
        <LocationCard
          linkTo="https://www.google.co.th/maps/search/%E0%B8%96%E0%B8%99%E0%B8%99%E0%B8%84%E0%B8%99%E0%B9%80%E0%B8%94%E0%B8%B4%E0%B8%99%E0%B9%80%E0%B8%8A%E0%B8%B5%E0%B8%A2%E0%B8%87%E0%B8%84%E0%B8%B2%E0%B8%99/@17.8982801,101.6614589,17z/data=!3m1!4b1?hl=th&entry=ttu9"
          imagePath={North2}
          thaiName={isEN ? "Chiang Khan Walking Street, Loei" : "ถนนคนเดินเชียงคาน เลย"}
          engName={isEN ? "Chiang Khan Walking Street, Loei": "ถนนคนเดินเชียงคาน เลย"}
        />
        <LocationCard
          linkTo="https://www.google.co.th/maps/search/%E0%B8%96%E0%B8%99%E0%B8%99%E0%B8%84%E0%B8%99%E0%B9%80%E0%B8%94%E0%B8%B4%E0%B8%99%E0%B9%80%E0%B8%8A%E0%B8%B5%E0%B8%A2%E0%B8%87%E0%B8%84%E0%B8%B2%E0%B8%99/@17.8982801,101.6614589,17z/data=!3m1!4b1?hl=th&entry=ttu"
          imagePath={North3}
          thaiName={isEN ? "Chiang Khan Walking Street, Loei" : "ถนนคนเดินเชียงคาน เลย"}
          engName={isEN ? "Chiang Khan Walking Street, Loei": "ถนนคนเดินเชียงคาน เลย"}
        />
      </div>
      
    </div>
  );
};

export default LocationsNft;
