import AboutNft from "../../components/about_nft/about_nft";
import AppAndWebsite from "../../components/app_and_website/app_and_website";
import LocationsNft from "../../components/locations_nft/locations_nft";
import CollectAmazingNft from "../../components/collect_nft/collect_amazing_nft";
import CollectYaksNft from "../../components/collect_nft/collect_yaks";
import PrivilegePartner from "../../components/partner/privilege_partner";
import AmazingNft from "../../components/amazing_nft/amazing_nft";
import YaksNft from "../../components/yaks_nft/yaks_nft";
import MainPartner from "../../components/partner/main_partner";
import AboutYaksNft from "../../components/about_yaks_nft/about_yaks_nft";
import Privilege from "../../components/privilege/privilege";
import IntroYaks from "../../components/intro_yaks/intro_yaks";

const Home = () => {
  return (
    <div>
       <IntroYaks />
      <CollectAmazingNft />
      <LocationsNft />
      <Privilege />
      <AmazingNft />
      <PrivilegePartner />
    </div>
  );
};

export default Home;
