const CollectNftElement = ({ imagePath, iconPath, header, description }) => {
  return (
    <div className="flex flex-col items-center md:w-1/3 xl:w-1/3">
      <div className="flex flex-row items-center mt-3">
        <div className="text-xl font-bold md:text-base xl:text-2xl">
          {header}
        </div>
      </div>
      <div className="text-center leading-8 text-lg font-light mx-3 mt-3 md:text-sm xl:w-9/12 xl:text-xl">
        {description}
      </div>
    </div>
  );
};

export default CollectNftElement;
