import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import Trip01 from "../../assets/privilege/Trip-01.jpg";
import Trip03 from "../../assets/privilege/Trip-03.jpg";
import BanYanTree13 from "../../assets/privilege/BanyanBKK-banner-13.jpeg";
import BanYanTree14 from "../../assets/privilege/BanyanPhuket-banner-14.jpeg";
import BanYanTree15 from "../../assets/privilege/BanyanVeya-banner-15.jpeg";
import BanYanTree16 from "../../assets/privilege/BanyanAngsana-banner-16.jpeg";
import BanYanTree17 from "../../assets/privilege/BanyanCassia-banner-17.jpeg";
import BanYanTree18 from "../../assets/privilege/BanyanHomm-banner-18.jpeg";
import Vietjet from "../../assets/privilege/vietjet.png";
import ChicCarRent from "../../assets/privilege/chic_car_rent.png";
import HungryHub from "../../assets/privilege/hungry_hub.png";
import Banner1 from "../../assets/privilege/ss2/banner1.jpg"
import Banner2 from "../../assets/privilege/ss2/banner2.jpg"
import Banner3 from "../../assets/privilege/ss2/banner3.png"
import Banner5 from "../../assets/privilege/ss2/banner5.jpg"
import Banner6 from "../../assets/privilege/ss2/banner6.jpg"
import Banner7 from "../../assets/privilege/ss2/banner7.jpg"
import Banner8 from "../../assets/privilege/ss2/banner8.jpg"
import Banner9 from "../../assets/privilege/ss2/banner9.jpg"
import Banner10 from "../../assets/privilege/ss2/banner10.jpg"
import Banner11 from "../../assets/privilege/ss2/banner11.jpg"
import Banner12 from "../../assets/privilege/ss2/banner12.jpg"
import Banner13 from "../../assets/privilege/ss2/banner13.jpg"
import Banner14 from "../../assets/privilege/ss2/banner14.jpg"
import Banner15 from "../../assets/privilege/ss2/banner15.jpg"
import Banner16 from "../../assets/privilege/ss2/banner16.jpg"
import Banner17 from "../../assets/privilege/ss2/banner17.jpg"
import Banner18 from "../../assets/privilege/ss2/banner18.jpg"
import Banner19 from "../../assets/privilege/ss2/banner19.jpg"
import Banner20 from "../../assets/privilege/ss2/banner20.jpg"
import Banner21 from "../../assets/privilege/ss2/banner21.jpg"
import Banner22 from "../../assets/privilege/ss2/banner22.jpg"
import Banner23 from "../../assets/privilege/ss2/banner23.jpg"
import Banner24 from "../../assets/privilege/ss2/banner24.jpg"
import Banner25 from "../../assets/privilege/ss2/banner25.png"
import Banner26 from "../../assets/privilege/ss2/banner26.jpg"
import Banner27 from "../../assets/privilege/ss2/banner27.jpg"
import Banner28 from "../../assets/privilege/ss2/banner28.png"
import Banner29 from "../../assets/privilege/ss2/banner29.jpg"

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const PrivilegeContent = () => {
  return (
    <Swiper
      slidesPerView={1}
      spaceBetween={0}
      centeredSlides={false}
      breakpoints={{
        768: {
          slidesPerView: 3,
          spaceBetween: 0,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 0,
        },
      }}
      modules={[Pagination]}
      className="mySwiper"
    >
      <SwiperSlide className="p-3">
        <img src={Banner1} className="rounded-3xl" />
      </SwiperSlide>
      <SwiperSlide className="p-3">
        <img src={Banner2} className="rounded-3xl" />
      </SwiperSlide>
      <SwiperSlide className="p-3">
        <img src={Banner3} className="rounded-3xl" />
      </SwiperSlide>
      <SwiperSlide className="p-3">
        <img src={Banner5} className="rounded-3xl" />
      </SwiperSlide>
      <SwiperSlide className="p-3">
        <img src={Banner6} className="rounded-3xl" />
      </SwiperSlide>
      <SwiperSlide className="p-3">
        <img src={Banner7} className="rounded-3xl" />
      </SwiperSlide>
      <SwiperSlide className="p-3">
        <img src={Banner8} className="rounded-3xl" />
      </SwiperSlide>
      <SwiperSlide className="p-3">
        <img src={Banner9} className="rounded-3xl" />
      </SwiperSlide>
      <SwiperSlide className="p-3">
        <img src={Banner10} className="rounded-3xl" />
      </SwiperSlide>
      <SwiperSlide className="p-3">
        <img src={Banner11} className="rounded-3xl" />
      </SwiperSlide>
      <SwiperSlide className="p-3">
        <img src={Banner12} className="rounded-3xl" />
      </SwiperSlide>
      <SwiperSlide className="p-3">
        <img src={Banner13} className="rounded-3xl" />
      </SwiperSlide>
      <SwiperSlide className="p-3">
        <img src={Banner14} className="rounded-3xl" />
      </SwiperSlide>
      <SwiperSlide className="p-3">
        <img src={Banner15} className="rounded-3xl" />
      </SwiperSlide>
      <SwiperSlide className="p-3">
        <img src={Banner16} className="rounded-3xl" />
      </SwiperSlide>
      <SwiperSlide className="p-3">
        <img src={Banner17} className="rounded-3xl" />
      </SwiperSlide>
      <SwiperSlide className="p-3">
        <img src={Banner18} className="rounded-3xl" />
      </SwiperSlide>
      <SwiperSlide className="p-3">
        <img src={Banner19} className="rounded-3xl" />
      </SwiperSlide>
      <SwiperSlide className="p-3">
        <img src={Banner20} className="rounded-3xl" />
      </SwiperSlide>
      <SwiperSlide className="p-3">
        <img src={Banner21} className="rounded-3xl" />
      </SwiperSlide>
      <SwiperSlide className="p-3">
        <img src={Banner22} className="rounded-3xl" />
      </SwiperSlide>
      <SwiperSlide className="p-3">
        <img src={Banner23} className="rounded-3xl" />
      </SwiperSlide>
      <SwiperSlide className="p-3">
        <img src={Banner24} className="rounded-3xl" />
      </SwiperSlide>
      <SwiperSlide className="p-3">
        <img src={Banner25} className="rounded-3xl" />
      </SwiperSlide>
      <SwiperSlide className="p-3">
        <img src={Banner26} className="rounded-3xl" />
      </SwiperSlide>
      <SwiperSlide className="p-3">
        <img src={Banner27} className="rounded-3xl" />
      </SwiperSlide>
      <SwiperSlide className="p-3">
        <img src={Banner28} className="rounded-3xl" />
      </SwiperSlide>
      <SwiperSlide className="p-3">
        <img src={Banner29} className="rounded-3xl" />
      </SwiperSlide>
    </Swiper>
  );
};

export default PrivilegeContent;
