import "./App.css";
import { Route, Routes } from "react-router-dom";
import Home from "./routes/home/home";
import Navigation from "./routes/navigation/navigation";
import Policy from "./routes/policy/policy";
import TermAndCondition from "./routes/termandcondition/termandcondition";
import HomeEN from "./routes/home/home_en";

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigation />}>
        <Route index element={<Home />} />
        <Route path="/en" element={<HomeEN />} />
        <Route path="/privacy" element={<Policy />} />
        <Route path="/term" element={<TermAndCondition />} />
      </Route>
    </Routes>
  );
};

export default App;
