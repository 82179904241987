import { useLocation } from "react-router-dom";
import RankAndLevel1 from "../../assets/amazing_nft/nft-1.png";
import RankAndLevel2 from "../../assets/amazing_nft/nft-2.png";
import AmazingNftCard from "./amazing_nft_card";

const AmazingNft = () => {
  const location = useLocation();
  const path = location.pathname;
  const isEN = path == "/en";
  return (
    <div className="flex flex-col items-center mt-24" id="rankandlevel">
      <div className="text-2xl font-semibold my-5 xl:text-3xl">
        RANK AND LEVEL
      </div>
      <div className="font-semibold text-lg px-7 mb-3 text-center md:text-xl xl:text-2xl">
        {isEN
          ? "Amazing Thailand NFTs Season 3 is divided into two levels, both of which offer the same benefits. The only difference is the chance of obtaining rare NFT art pieces."
          : "Amazing Thailand NFTs Season 3  แบ่งเป็น 2 ระดับ โดยทั้งสองระดับจะได้สิทธิประโยชน์เท่ากัน ต่างกันในส่วนของโอกาสเกิดของชิ้นงาน NFT เท่านั้น"}
      </div>
      <div className="flex flex-col md:flex-row md:mt-5">
        <AmazingNftCard
          imagePath={RankAndLevel1}
          header="Normal"
          description={[isEN ? "Landmark image" : "รูปภาพประจำสถานที่"]}
        />
        <AmazingNftCard
          imagePath={RankAndLevel2}
          header="Rare"

          description={[
            isEN ? "A seal of the landmark" : "มีตราประทับชื่อจังหวัด",
            isEN ? "Province’s name is affixed on the NFT." : "มีตราประทับสถานที่จังหวัด"
          ]}
        />
      </div>
    </div>
  );
};

export default AmazingNft;
