import { useLocation } from "react-router-dom";
import PrivilegeContent from "./privilege_content";

const Privilege = () => {
  const location = useLocation();
  const path = location.pathname;
  const isEN = path == "/en";
  return (
    <div className="my-32" id="privilege" scrollTo="privilege">
      <div className="text-2xl font-semibold text-center xl:text-3xl">
        {isEN ? "Travel & Lifestyle Benefits" : "สิทธิประโยชน์ Travel & Lifestyle"}
      </div>
      <div className="text-lg px-16 text-center my-3 xl:text-xl">
        {isEN
          ? "Simply travel and collect NFTs in the designated landmarks to enjoy a variety of benefits."
          : "เพียงออกเดินทางสะสม NFT ก็เพลิดเพลินกับสิทธิประโยชน์มากมาย"}
      </div>
      <PrivilegeContent />
    </div>
  );
};

export default Privilege;
