import Mix from "../../assets/privilege_partner/รวมโลโก้.jpg";

const IconFrame = ({ children }) => {
  return (
    <div className="w-[200px] h-[200px] flex flex-col justify-center md:w-[100px] md:h-[100px] xl:w-[130px] ">
      {children}
    </div>
  );
};

const PrivilegePartner = () => {
  return (
    <div>
      <div className="flex flex-col items-center mt-24">
        <div className="text-3xl font-semibold mt-5 ">Privilege Partners</div>
        <img src={Mix}/>
      </div>
    </div>
  );
};

export default PrivilegePartner;
